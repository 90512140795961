import styled from 'styled-components';

const loginStyles = {
  Container: styled.div`
    height: 100vh;
    display: flex;
    overflow: hidden !important;
  `,

  Title: styled.div`
    font-family: 'Open Sans Semi Bold';
    font-style: normal;
    font-size: 40px;
    line-height: 116%;
    text-align: left;
    margin-bottom: 56px;
    color: #333333;
  `,

  Label: styled.label`
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    color: #919ca7;
  `,

  ButtonContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,

  ImageContainer: styled.div`
    height: 100vh;
    width: 50vw;
    img {
      object-fit: contain;
      padding-top: 20px;
      height: 100%;
    }
  `,

  Content: styled.div`
    display: flex;
    margin-bottom: 150px;
    width: calc(30% + 43px);
    padding-left: 100px;
    flex-direction: column;
    justify-content: center;
  `,
};

export default loginStyles;
