import styled from 'styled-components';

export const controlPanelStyles = {
  Header: styled.div`
    display: flex;
    padding-top: 25px;
    padding-right: 30px;
    padding-left: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eceff2;
    justify-content: space-between;
  `,
  HeaderLeft: styled.div`
    display: flex;
    align-items: center;
  `,
  HeaderTitle: styled.div`
    margin-left: 16px;
    margin-right: 24px;
  `,
  HeaderTime: styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 10px;
    border-radius: 20px;
    background: #f8f8ff;
    background: ${(props: any) =>
      props.borderColor === 'Выдано' ? '#C8E3B7' : '#F8F8FF'};
    border: 1px solid
      ${(props: any) => (props.borderColor === 'Выдано' ? '#C8E3B7' : '#DCE3E4')};
  `,
};
