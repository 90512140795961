import { UserStorageProps, LoginResponseProps } from 'utils/ts/interfaces';

export const setTokenFromStorage = (token: { access: string; refresh: string }) => {
  localStorage.setItem('token', JSON.stringify(token));
};

export const getTokenFromStorage = () => {
  const storage: string | null = localStorage.getItem('token');
  const token: UserStorageProps | null = storage ? JSON.parse(storage).access : null;
  return token;
};

export const getTokenFromStore = () => {
  const storage: string | null = localStorage.getItem('token');
  const token: LoginResponseProps | null = storage ? JSON.parse(storage) : null;
  return token;
};
export const clearLocalStorage = () => localStorage.removeItem('token');

export const getTokensFromStorage = () => {
  const storage: string | null = localStorage.getItem('token');
  const token: LoginResponseProps | null = storage ? JSON.parse(storage) : null;
  return token;
};
