import styled from 'styled-components';

export const headerStyles = {
  Container: styled.div`
    display: flex;
    padding: 17px 100px;
    justify-content: space-between;

    @media (min-width: 1440px) {
      padding: 17px 135px;
    }
  `,
  Line: styled.div`
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 1px;
    opacity: 0.1;

    :before {
      content: '';
      display: block;
      position: absolute;
      height: 31px;
      width: 100%;
      background: #333333;
    }
  `,

  Links: styled.div`
    display: flex;
    align-items: center;
    gap: 16px;

    @media (min-width: 1440px) {
      gap: 32px;
    }
  `,

  Link: styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Open Sans';
    font-size: 13px;
    line-height: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;

    @media (min-width: 1440px) {
      font-size: 14px;
    }

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background: #49a3a6;
      border-radius: 2px 2px 0px 0px;
      bottom: -17px;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }

    a {
      text-decoration: none;
      text-align: center;
      color: #333333;
      background-color: transparent;
    }

    ${(props: { active: boolean }) =>
      props.active === true
        ? `
      &:before {
        opacity: 1;
      }
      a {
        color: #919ca7;
      }`
        : null}
  `,

  Settings: styled.div<{ active: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Open Sans';
    font-size: 13px;
    line-height: 100%;
    height: 100%;
    transition: all 0.2s ease-in-out;

    @media (min-width: 1440px) {
      font-size: 14px;
    }

    a {
      text-decoration: none;
      text-align: center;
      color: #333333;
      background-color: transparent;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      background: #49a3a6;
      border-radius: 2px 2px 0px 0px;
      bottom: -17px;
      opacity: 0;
      transition: all 0.2s ease-in-out;
      width: 48px;
    }

    ${(props: { active: boolean }) =>
      props.active === true
        ? `
        &:before {
          opacity: 1;
        }
        a {
          color: #919ca7;
        }
        svg path:first-child {
          fill: rgba(51, 51, 51, 1);
          stroke: rgba(51, 51, 51, 1);
        }
      `
        : null}

    &:hover {
      svg path:first-child {
        fill: rgba(51, 51, 51, 1);
        stroke: rgba(51, 51, 51, 1);
      }
    }
    svg path:first-child {
      fill: rgba(178, 184, 191, 1);
      stroke: rgba(178, 184, 191, 1);
    }
  `,

  Email: styled.div`
    color: #333333 !important;
    font-size: 14px;
  `,

  Logout: styled.button`
    outline: none;
    cursor: pointer;
    border: none;
    background: transparent;
    display: flex;
    align-items: center;
    stroke: #b2b8bf;
    transition: all 0.25s ease-in-out;

    :hover {
      stroke: #333333;
    }
  `,

  Right: styled.div`
    display: flex;
    gap: 16px;
    align-items: center;

    @media (min-width: 1440px) {
      gap: 32px;
    }
  `,
};
