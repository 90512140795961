import { useState, useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import { Select as SelectMUI } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { OptionsProps } from 'utils/ts/interfaces';
import { Option, InputWrapper, Input, Label, Placeholder, Empty } from './styles';

interface SelectProps {
  id?: number;
  active: string;
  options: OptionsProps[];
  label?: string;
  placeholder?: string;
  variant?: 'light';
  onScrollEnd?: () => void;
  onChange: (value: any) => void;
  returnOptionId?: boolean;
}

function getStyles(name: string, personName: string, theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const Select = ({
  active,
  options,
  label,
  onChange,
  placeholder,
  variant,
  onScrollEnd,
  id,
  returnOptionId,
}: SelectProps) => {
  const theme = useTheme();
  const [value, setValue] = useState<string>(active);
  const [scroll, setScroll] = useState(false);
  const menuListId = `menu-list-for-select-${id}`;

  useEffect(() => {
    if (value !== active) setValue(active);
  }, [active]);

  useEffect(() => {
    if (scroll && onScrollEnd) {
      onScrollEnd();
      setScroll(false);
    }
  }, [scroll]);

  const addScrollEvent = async () => {
    if (onScrollEnd) {
      const getElem = new Promise<Element | null | undefined>((resolve, reject) => {
        setTimeout(
          () =>
            resolve(
              document.querySelector(`#${menuListId}`)?.querySelector('.MuiList-root'),
            ),
          50,
        );
      });

      const container = await getElem;

      if (container) {
        let scrollPosition = 0;
        container.addEventListener('scroll', () => {
          const scrolled = container.scrollTop;

          if (scrolled > scrollPosition) {
            const scrollHeight = container.scrollHeight;
            const clientHeight = container.clientHeight;

            if (scrollHeight - scrolled === clientHeight) {
              setScroll(true);
            }
          }

          scrollPosition = scrolled;
        });
      }
    }
  };

  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { value },
    } = event;
    setValue(value);
    onChange(value);
  };

  const MenuProps = {
    id: menuListId,
    MenuListProps: {
      className: 'options-list',
    },
    PaperProps: {
      style: {
        width: variant === 'light' ? 420 : 270,
        minWidth: 'none',
        boxShadow: '0px 4px 34px rgba(0, 0, 0, 0.15)',
        borderRadius: 10,
        marginTop: 8,
      },
    },
  };

  return (
    <InputWrapper
      sx={{ m: 1, width: variant === 'light' ? 420 : 270 }}
      className={`${label && 'withLabel'} ${variant === 'light' && 'light'}`}
    >
      {label && <Label htmlFor={`filter-${label}`}>{label}</Label>}
      <SelectMUI
        id={`filter-${label}`}
        value={value}
        onChange={handleChange}
        onOpen={onScrollEnd ? addScrollEvent : undefined}
        displayEmpty
        renderValue={selected => {
          if (selected.length === 0 && placeholder) {
            return <Placeholder>{placeholder}</Placeholder>;
          }
          // @ts-ignore
          return returnOptionId ? selected.value : selected;
        }}
        input={<Input className={`${variant === 'light' && 'light'}`} />} // @ts-ignore
        MenuProps={MenuProps}
      >
        {options.length > 0 ? (
          options.map(item => (
            <Option
              key={item.id} // @ts-ignore
              value={returnOptionId ? item : item.value}
              style={getStyles(
                String(returnOptionId ? item.id : item.value), // @ts-ignore
                String(returnOptionId ? value.value : value),
                theme,
              )}
              className={
                // @ts-ignore
                (returnOptionId ? value.value : value) == item.value ? 'hidden ' : ''
              }
            >
              {item.value}
            </Option>
          ))
        ) : (
          <Empty>Значения отсутствуют</Empty>
        )}
      </SelectMUI>
    </InputWrapper>
  );
};
