import { iconsCollection } from 'utils/IconsCollection';
import { dynamicIcon } from './styles';

interface IconProps {
  name: any;
}

const { Wrapper } = dynamicIcon;

export const DynamicIcon = ({ name }: IconProps) => {
  const Icon = () => iconsCollection[name];

  return (
    <Wrapper>
      <Icon />
    </Wrapper>
  );
};
