import { InputDataProps } from 'utils/ts/interfaces';

export const profileAgent: InputDataProps[] = [
  {
    id: 1,
    value: '',
    field: 'fullName',
    placeholder: 'Ф.И.О',
    required: true,
  },
  {
    id: 3,
    value: '',
    field: 'phoneNumber',
    placeholder: 'Телефон',
    mask: '+7 999 999 99 99',
    required: true,
  },
  {
    id: 2,
    value: '',
    field: 'email',
    type: 'email',
    placeholder: 'Почта',
    required: true,
  },
  {
    id: 4,
    value: '',
    field: 'group',
    type: 'select',
    placeholder: 'Автосалон (Необязательно)',
    options: [],
    returnOptionId: true,
  },
];

export const profileManager: InputDataProps[] = [
  {
    id: 1,
    value: '',
    field: 'fullName',
    placeholder: 'Ф.И.О',
    required: true,
  },
  {
    id: 3,
    value: '',
    field: 'phoneNumber',
    placeholder: 'Телефон',
    type: 'tel',
    mask: '+7 999 999 99 99',
    required: true,
  },
  {
    id: 2,
    value: '',
    field: 'email',
    type: 'email',
    placeholder: 'Почта',
    required: true,
  },

  {
    id: 4,
    value: '',
    field: 'password',
    type: 'password',
    placeholder: 'Пароль',
    required: true,
  },
];

export const profileDirector: InputDataProps[] = [
  {
    id: 1,
    value: '',
    field: 'fullName',
    placeholder: 'Ф.И.О',
    required: true,
  },
  {
    id: 3,
    value: '',
    field: 'phoneNumber',
    placeholder: 'Телефон',
    type: 'tel',
    mask: '+7 999 999 99 99',
    required: true,
  },
  {
    id: 2,
    value: '',
    field: 'email',
    type: 'email',
    placeholder: 'Почта',
    required: true,
  },
  {
    id: 4,
    value: '',
    field: 'group',
    type: 'select',
    placeholder: 'Автосалон',
    options: [],
    returnOptionId: true,
    required: true,
  },
  {
    id: 4,
    value: '',
    field: 'password',
    type: 'password',
    placeholder: 'Пароль',
    required: true,
  },
];

export const dealershipsInfo = [
  {
    id: 1,
    value: '',
    field: 'name',
    placeholder: 'Название',
    required: true,
  },
];
