import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../generateState';
import { ActiveStatuses } from 'utils/ts/customTypes';

interface ActiveStatusesProps {
  activeStatus: ActiveStatuses;
}
const initialState: ActiveStatusesProps = { activeStatus: null };

const slice = createSlice({
  name: 'activeStatus',
  initialState,
  reducers: {
    setActiveStatus: (state, { payload }: PayloadAction<ActiveStatuses>) => {
      state.activeStatus = payload;
    },
  },
});

export const { setActiveStatus } = slice.actions;

export const selectActiveStatus = (state: RootState) => {
  return state.activeStatus;
};

export default slice.reducer;
