export const tabs = [
  {
    id: 1,
    title: 'От агента',
    param: 'from-agent',
  },
  {
    id: 2,
    title: 'Зарегистрировались сами',
    param: 'registered',
  },
];

export const table = [
  {
    type: tabs[0].param,
    titles: [
      { title: 'Имя', id: 1 },
      { title: 'Телефон', id: 2 },
      { title: 'Почта', id: 3 },
      { title: 'ИНН', id: 4, name: 'uin' },
      { title: 'Компания', id: 5 },
    ],
  },
  {
    type: tabs[1].param,
    titles: [
      { title: 'Имя', id: 1 },
      { title: 'Телефон', id: 2 },
      { title: 'Почта', id: 3 },
    ],
  },
];
