import CircularProgress from '@mui/material/CircularProgress';
import { customTheme } from 'styles/theme';
import { loadingStyles } from './styles';

const { RelativeBlock } = customTheme;
const { Container, Content } = loadingStyles;

interface LoaderProps {
  active: boolean;
  children?: JSX.Element | JSX.Element[];
}

export const Loader = ({ active, children }: LoaderProps) => {
  return (
    <RelativeBlock>
      <Container active={active}>
        <CircularProgress />
      </Container>
      <Content active={active}>{children && children}</Content>
    </RelativeBlock>
  );
};
