import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import InputMask from 'react-input-mask';
import {
  Box,
  Button,
  IconButton,
  FormHelperText,
  FormControl,
  OutlinedInput,
  TextareaAutosize,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { useAppDetailsCheck } from 'hooks';
import { setCountNewApp } from 'store/slices/countNewApp';
import { setDetails } from 'store/slices/applicationDetails';
import { sendRequest, baseApiUrl, findApplicationPayment } from 'utils';
import { CloseIcon } from 'assets/icons';
import styles from './styles.module.scss';

interface FormDialogProps {
  title: string;
  show: boolean;
  toggleShow: (show: boolean) => void;
  type?: string;
  defaultValue: string | number;
  fieldName: string;
  placeholder?: string;
  mask?: string;
}

const {
  form,
  wrapper,
  close,
  icon,
  header,
  headline,
  inputWrapper,
  input,
  textarea,
  actions,
  button,
  body,
  helper,
  invalid,
} = styles;

export const FormDialog = ({
  title,
  show,
  toggleShow,
  type,
  defaultValue,
  fieldName,
  placeholder,
  mask,
}: FormDialogProps) => {
  const [value, setValue] = useState(defaultValue);
  const [isInvalid, setIsInvalid] = useState(false);
  const [helperText, setHelperText] = useState('');
  const detailInfo = useAppDetailsCheck();
  const { id } = useParams();
  const url = `${baseApiUrl}dashboard/application/${id}/${
    type === 'payment' ? `${type}/` : ''
  }`;
  const idForHelperText = 'outlined-helper-text-' + type;
  const dispatch = useDispatch();

  const closeModal = () => {
    setValue('');
    setIsInvalid(false);
    toggleShow(false);
  };

  const giveAnError = (error: any) => {
    const { message, field } = error;
    setIsInvalid(true);
    setHelperText(`${message}`);
  };

  const sendForm = (e: any) => {
    e.preventDefault();

    if (value !== defaultValue) {
      let params = {
        method: 'PUT',
        data: {
          [fieldName]: value,
        },
      };

      if (type === 'payment') {
        const paymentStatus = detailInfo.payment?.status;
        if (paymentStatus) params.data.status = paymentStatus;
      }

      sendRequest(url, params).then(({ data }: any) => {
        const { error } = data;
        if (error !== undefined) {
          giveAnError(error.details[0]);
        } else {
          const { payment, countNewApp, ...other } = data;

          setIsInvalid(false);
          toggleShow(false);

          let newInfo = setDetails({ ...detailInfo, ...other, payment });

          if (payment && payment.length > 0 && id)
            newInfo = setDetails({
              ...detailInfo,
              ...other,
              payment: findApplicationPayment(id, payment),
            });
          if (type === 'payment') {
            newInfo = setDetails({
              ...detailInfo,
              payment: { ...detailInfo.payment, ...other },
            });
          }

          dispatch(newInfo);
          if (countNewApp !== undefined) dispatch(setCountNewApp(countNewApp));
        }
      });
    } else {
      giveAnError({ message: 'Значение не изменилось' });
    }
  };

  const changeInputValue = (event: any) => {
    setIsInvalid(false);
    setValue(event.target.value);
  };

  const commonInputProps = {
    inputProps: {
      'aria-label': 'weight',
    },
    type: type ? type : 'text',
    className: input,
    fullWidth: true,
    required: true,
    autoFocus: true,
  };

  return (
    <Dialog open={show} onClose={closeModal} className={`${form} form-dialog`}>
      <Box component="form" onSubmit={e => sendForm(e)} className={wrapper}>
        <DialogTitle className={header}>
          <div className={headline}>{title}</div>
          {show ? (
            <IconButton aria-label="close" onClick={closeModal} className={close}>
              <CloseIcon className={icon} />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent className={body}>
          <FormControl
            variant="outlined"
            className={`${inputWrapper} ${isInvalid && invalid}`}
          >
            {type && type === 'textarea' ? (
              <TextareaAutosize
                className={textarea}
                minRows={5}
                maxRows={5}
                placeholder={placeholder}
                value={value}
                autoFocus
                onChange={changeInputValue}
                required
              />
            ) : (
              <>
                {mask && type === 'tel' ? (
                  <InputMask mask={mask} value={value} onChange={changeInputValue}>
                    {/* @ts-ignore */}
                    {(inputProps: any) => (
                      <OutlinedInput {...inputProps} {...commonInputProps} />
                    )}
                  </InputMask>
                ) : (
                  <OutlinedInput
                    value={value}
                    onChange={changeInputValue}
                    {...commonInputProps}
                  />
                )}
              </>
            )}
            <FormHelperText title={helperText} className={helper} id={idForHelperText}>
              {helperText}
            </FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions className={actions}>
          <Button type="submit" className={button}>
            Сохранить
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
