import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { useMainLoader } from 'hooks/useMainLoader';
import { setMainLoader } from 'store/slices/mainLoader';
import { Header } from 'components/Header';
import { mainLayoutStyles } from 'components/MainLayout/styles';
import styles from './styles.module.scss';

const MainContainer = mainLayoutStyles.MainContainer;

interface Props {
  children: JSX.Element | JSX.Element[];
}

const LayoutWrapper = ({ children }: Props) => (
  <>
    <Header />
    <MainContainer>{children}</MainContainer>
  </>
);

export const Layout = () => {
  const { pathname, state } = useLocation();
  const [loaded, setLoaded] = useState(true);
  const dispatch = useDispatch();
  const loading = useMainLoader();
  const { layout, show } = styles;

  useEffect(() => {
    if (state?.prev) {
      dispatch(setMainLoader(true));
    } else {
      dispatch(setMainLoader(false));
    }
  }, [pathname]);

  useEffect(() => {
    if (!loaded) {
      setLoaded(loading);
    } else {
      setTimeout(() => setLoaded(loading), 250);
    }
  }, [loading]);

  return (
    <LayoutWrapper>
      <div className={`${layout} ${!loaded ? show : ''}`}>
        <Outlet />
      </div>
    </LayoutWrapper>
  );
};
