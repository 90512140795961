export const statuses = [
  {
    id: 1,
    value: 'Новая',
  },
  {
    id: 2,
    value: 'В работе',
  },
  {
    id: 3,
    value: 'Сбор документов',
  },
  {
    id: 4,
    value: 'Одобрение',
  },
  {
    id: 5,
    value: 'Одобрен',
  },
  {
    id: 6,
    value: 'Получен аванс',
  },
  {
    id: 7,
    value: 'Поставка техники',
  },
  {
    id: 8,
    value: 'Выдано',
  },
  {
    id: 9,
    value: 'Отказ клиента',
  },
  {
    id: 10,
    value: 'Отложил покупку',
  },
];
