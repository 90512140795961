import { getTokensFromStorage, setTokenFromStorage } from 'utils/localStorage';
interface GetProps {
  (): any;
}

export const useAuthCheck = async (getUser: GetProps) => {
  const resp = await getUser()
    .unwrap()
    .then((res: any) => {
      const { error, access, refresh, ...data } = res;
      if (!error) {
        const params = {
          access: access ? access : getTokensFromStorage()?.access,
          refresh: refresh ? refresh : getTokensFromStorage()?.refresh,
        };
        setTokenFromStorage(params);
        return data;
      } else {
        return error;
      }
    })
    .catch((error: any) => console.log(error));
  return resp;
};
