// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_button__jaypH {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 14px 16px !important;
  gap: 10px !important;
  border: 1px solid #49a3a6 !important;
  border-radius: 40px !important;
  font-family: "Open Sans Semi Bold" !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 116% !important;
  color: #333333 !important;
  text-transform: none !important;
  width: 100%;
  margin-top: 24px !important;
}

.styles_paid__fXTwy {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Open Sans";
  font-style: normal;
  color: #5aaf26;
  width: 100%;
}
.styles_paid__fXTwy .styles_priority__OWsVh,
.styles_paid__fXTwy .styles_secondary__GFowO {
  line-height: 116%;
}
.styles_paid__fXTwy .styles_priority__OWsVh svg,
.styles_paid__fXTwy .styles_secondary__GFowO svg {
  stroke: #5aaf26;
}
.styles_paid__fXTwy .styles_priority__OWsVh {
  display: flex;
  font-family: "Open Sans Semi Bold";
  font-size: 30px;
  margin: 8px 0 17px;
}
.styles_paid__fXTwy .styles_priority__OWsVh svg {
  width: 27px;
  height: 35px;
  fill: #5aaf26;
}
.styles_paid__fXTwy .styles_secondary__GFowO {
  font-family: "Open Sans";
  font-size: 12px;
}
.styles_paid__fXTwy .styles_secondary__GFowO svg {
  height: 8px;
  margin-right: 6px;
}`, "",{"version":3,"sources":["webpack://./src/components/AgentCard/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,wBAAA;EACA,8BAAA;EACA,kCAAA;EACA,8BAAA;EACA,6BAAA;EACA,oBAAA;EACA,oCAAA;EACA,8BAAA;EACA,6CAAA;EACA,6BAAA;EACA,0BAAA;EACA,4BAAA;EACA,yBAAA;EACA,+BAAA;EACA,WAAA;EACA,2BAAA;AACF;;AAEA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,wBAAA;EACA,kBAAA;EACA,cAAA;EACA,WAAA;AACF;AACE;;EAEE,iBAAA;AACJ;AACI;;EACE,eAAA;AAEN;AAEE;EACE,aAAA;EACA,kCAAA;EACA,eAAA;EACA,kBAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;EACA,aAAA;AAAN;AAIE;EACE,wBAAA;EACA,eAAA;AAFJ;AAII;EACE,WAAA;EACA,iBAAA;AAFN","sourcesContent":[".button {\n  display: flex !important;\n  flex-direction: row !important;\n  justify-content: center !important;\n  align-items: center !important;\n  padding: 14px 16px !important;\n  gap: 10px !important;\n  border: 1px solid #49a3a6 !important;\n  border-radius: 40px !important;\n  font-family: 'Open Sans Semi Bold' !important;\n  font-style: normal !important;\n  font-size: 14px !important;\n  line-height: 116% !important;\n  color: #333333 !important;\n  text-transform: none !important;\n  width: 100%;\n  margin-top: 24px !important;\n}\n\n.paid {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  font-family: 'Open Sans';\n  font-style: normal;\n  color: #5aaf26;\n  width: 100%;\n\n  .priority,\n  .secondary {\n    line-height: 116%;\n\n    svg {\n      stroke: #5aaf26;\n    }\n  }\n\n  .priority {\n    display: flex;\n    font-family: 'Open Sans Semi Bold';\n    font-size: 30px;\n    margin: 8px 0 17px;\n\n    svg {\n      width: 27px;\n      height: 35px;\n      fill: #5aaf26;\n    }\n  }\n\n  .secondary {\n    font-family: 'Open Sans';\n    font-size: 12px;\n\n    svg {\n      height: 8px;\n      margin-right: 6px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `styles_button__jaypH`,
	"paid": `styles_paid__fXTwy`,
	"priority": `styles_priority__OWsVh`,
	"secondary": `styles_secondary__GFowO`
};
export default ___CSS_LOADER_EXPORT___;
