import { useState, useEffect } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import usePagination from '@mui/material/usePagination';
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos';
import { determinePageNumber } from 'utils';
import styles from './styles.module.scss';

interface PaginationProps {
  count: number;
  changePage?: (page: number | null) => void;
}

const { pagination, action, next, prev, pages, buttonPage, active, ellipsis, locked } =
  styles;

export const Pagination = ({ count }: PaginationProps) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = searchParams.get('page');
  const search = searchParams.get('search') || '';
  const filterStatus = searchParams.get('status') || '';
  const filterManager = searchParams.get('manager') || '';

  useEffect(() => {}, [location.search]);

  const togglePage = (newPage: number | null) => {
    if (newPage) {
      let queries: any = { page: `${newPage}` };

      if (search?.length) {
        queries = { ...queries, search };
      }
      if (filterStatus?.length) {
        queries = { ...queries, status: filterStatus };
      }
      if (filterManager?.length) {
        queries = { ...queries, manager: filterManager };
      }

      setSearchParams(queries);
    }
  };

  const { items } = usePagination({
    count: count,
    page: Number(currentPage) || 1,
  });

  return (
    <>
      {count > 1 ? (
        <nav className={pagination}>
          <ul className={pages}>
            {items.map(({ page, type, selected, disabled }, index) => {
              let children = null;
              if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                children = <div className={`${buttonPage} ${ellipsis}`}>…</div>;
              } else if (type === 'page') {
                children = (
                  <button
                    type="button"
                    onClick={e => togglePage(page)}
                    className={`${buttonPage} ${selected ? active : ''}`}
                  >
                    {page}
                    <span />
                  </button>
                );
              } else {
                children = (
                  <button
                    type="button"
                    onClick={e => togglePage(page)}
                    className={`${buttonPage} ${action} ${
                      type === 'next' ? next : prev
                    } ${disabled && locked}`}
                  >
                    <ArrowForwardIcon />
                  </button>
                );
              }

              return <li key={index}>{children}</li>;
            })}
          </ul>
        </nav>
      ) : null}
    </>
  );
};
