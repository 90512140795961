// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_option__33fjy {
  padding: 0 !important;
  width: 100%;
  border-radius: 40px !important;
  background: transparent;
  transition: all 0.25s ease-in-out;
}
.styles_option__33fjy:hover {
  background: #eceff2;
}
.styles_option__33fjy.styles_hidden__CFS8M {
  display: none;
}
.styles_option__33fjy:nth-last-child(2) {
  margin-top: 12px;
}
.styles_option__33fjy:nth-last-child(2):before {
  content: "";
  display: block;
  position: absolute;
  top: -6px;
  left: 8px;
  width: calc(100% - 16px);
  height: 1px;
  background-color: #e3e8ef;
}

.styles_input__CxFxC {
  padding: 0;
  gap: 6px;
  width: 220px;
  height: 45px;
  background: #ffffff;
  border-radius: 10px;
  transition: all 0.25s ease-in-out;
}
.styles_input__CxFxC > div {
  padding: 6px 43px 6px 8px !important;
}
.styles_input__CxFxC > div:before {
  content: "";
  display: block;
  position: absolute;
  right: 43px;
  top: 0;
  width: 1px;
  height: 100%;
  background-color: #e3e8ef;
}
.styles_input__CxFxC > svg {
  right: 10px;
}
.styles_input__CxFxC fieldset {
  border: 1px solid #e3e8ef !important;
  box-shadow: 0px 0px 9px rgba(41, 97, 99, 0) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/SelectStatus/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,WAAA;EACA,8BAAA;EACA,uBAAA;EACA,iCAAA;AACF;AACE;EACE,mBAAA;AACJ;AAEE;EACE,aAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAGI;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,SAAA;EACA,SAAA;EACA,wBAAA;EACA,WAAA;EACA,yBAAA;AADN;;AAMA;EACE,UAAA;EACA,QAAA;EACA,YAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,iCAAA;AAHF;AAKE;EACE,oCAAA;AAHJ;AAKI;EACE,WAAA;EACA,cAAA;EACA,kBAAA;EACA,WAAA;EACA,MAAA;EACA,UAAA;EACA,YAAA;EACA,yBAAA;AAHN;AAOE;EACE,WAAA;AALJ;AAQE;EACE,oCAAA;EACA,sDAAA;AANJ","sourcesContent":[".option {\n  padding: 0 !important;\n  width: 100%;\n  border-radius: 40px !important;\n  background: transparent;\n  transition: all 0.25s ease-in-out;\n\n  &:hover {\n    background: #eceff2;\n  }\n\n  &.hidden {\n    display: none;\n  }\n\n  &:nth-last-child(2) {\n    margin-top: 12px;\n\n    &:before {\n      content: '';\n      display: block;\n      position: absolute;\n      top: -6px;\n      left: 8px;\n      width: calc(100% - 16px);\n      height: 1px;\n      background-color: #e3e8ef;\n    }\n  }\n}\n\n.input {\n  padding: 0;\n  gap: 6px;\n  width: 220px;\n  height: 45px;\n  background: #ffffff;\n  border-radius: 10px;\n  transition: all 0.25s ease-in-out;\n\n  > div {\n    padding: 6px 43px 6px 8px !important;\n\n    &:before {\n      content: '';\n      display: block;\n      position: absolute;\n      right: 43px;\n      top: 0;\n      width: 1px;\n      height: 100%;\n      background-color: #e3e8ef;\n    }\n  }\n\n  > svg {\n    right: 10px;\n  }\n\n  fieldset {\n    border: 1px solid #e3e8ef !important;\n    box-shadow: 0px 0px 9px rgba(41, 97, 99, 0) !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"option": `styles_option__33fjy`,
	"hidden": `styles_hidden__CFS8M`,
	"input": `styles_input__CxFxC`
};
export default ___CSS_LOADER_EXPORT___;
