import { useUserCheck } from 'hooks/useUserCheck';
import { Redirect } from './Redirect';

interface Props {
  children: JSX.Element;
  forRole?: number;
}

export const PrivateRoute = ({ children, forRole }: Props) => {
  const user = useUserCheck();

  if (!user) {
    return <Redirect link="/login" />;
  }
  if (forRole && user?.role !== forRole) {
    return <Redirect link="/application/new" />;
  }
  return children;
};

export default PrivateRoute;
