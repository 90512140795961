import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentApplication } from 'store/slices/applicationDetails';

export const useAppDetailsCheck = () => {
  const { applicationDetails } = useSelector(selectCurrentApplication);

  return useMemo(() => applicationDetails, [applicationDetails]);
};

export const useAppDetailsStatusCheck = () => {
  const { applicationDetails } = useSelector(selectCurrentApplication);

  return useMemo(() => applicationDetails.status, [applicationDetails.status]);
};
