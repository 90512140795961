import { useState, useEffect } from 'react';
import { DropzoneAreaBase, FileObject } from 'material-ui-dropzone';
import { formatBytes } from 'utils/dataTransformation';
import { ScreenWrapper, IconWrapper } from './styles';
import { DocIcon } from 'assets/icons';

const DropDownIcon = () => (
  <IconWrapper>
    <DocIcon />
  </IconWrapper>
);

interface UploadScreenProps {
  maxCount?: number;
  accept?: string[];
  onChange: (newFile: any) => void;
  files: FileObject[];
  maxFileSize?: number;
}

export const UploadScreen = ({
  maxCount,
  onChange,
  accept,
  maxFileSize,
  files,
}: UploadScreenProps) => {
  const [fileObjects, setFileObjects] = useState<FileObject[]>(files);
  const printcount = maxCount ? maxCount : 5;
  const maxSize = maxFileSize ? maxFileSize : Math.pow(1024, 2) * 5;

  useEffect(() => {
    setFileObjects(files);
  }, [files]);

  const onAdd = (acceptedFiles: FileObject[]) => {
    onChange(acceptedFiles);
  };

  const onReject = (rejectedFile: File, acceptedFiles: string[]) => {
    let message = `Файл ${rejectedFile.name} превышает допустимый размер в ${formatBytes(
      maxSize,
      1,
    )}`;
    if (rejectedFile.size > maxSize) {
      message = `Файл ${rejectedFile.name} превышает допустимый размер в ${formatBytes(
        maxSize,
        1,
      )}`;
    } else {
      const fileType = rejectedFile.name.split('.').at(-1);
      const noAccepted = acceptedFiles.filter(item => item !== fileType);
      if (noAccepted.length > 0) {
        message = `К загрузке доступны только файлы с расширением ${acceptedFiles.join(
          ', ',
        )}`;
      } else {
        message = `При загрузке файла ${rejectedFile.name} произошла ошибка`;
      }
    }

    return message;
  };

  const onExeed = () =>
    `Превышено максимально допустимое количество в ${printcount} файлов`;

  return (
    <ScreenWrapper>
      <DropzoneAreaBase
        //@ts-ignores
        Icon={DropDownIcon}
        showAlerts={['error']}
        alertSnackbarProps={{
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          autoHideDuration: null,
        }}
        onAdd={onAdd}
        getDropRejectMessage={onReject}
        getFileLimitExceedMessage={onExeed}
        maxFileSize={maxSize}
        useChipsForPreview
        showFileNames
        fileObjects={fileObjects}
        filesLimit={printcount}
        dropzoneText="Перенесите или загрузите файл"
        acceptedFiles={accept}
      />
    </ScreenWrapper>
  );
};
