// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_arrowBack__zgnIu {
  width: 40px;
  height: 40px;
  background: #eceff2;
  border: none;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.styles_arrowBack__zgnIu img {
  width: 5.83px;
  height: 11.67px;
}

.styles_responsible__cHh3h {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: "Open Sans";
  font-style: normal;
  font-size: 13px;
  line-height: 140%;
  color: #333333;
}

.styles_priority__JgoBj {
  font-family: "Open Sans Semi Bold";
  font-style: normal;
  font-size: 18px;
  line-height: 116%;
  color: #333333;
}

.styles_secondary__DZnxM {
  font-family: "Open Sans";
  font-style: normal;
  font-size: 14px;
  line-height: 140%;
  color: #919ca7;
}`, "",{"version":3,"sources":["webpack://./src/components/ApplicationControlPanel/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;EACA,eAAA;AACJ;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,wBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAGA;EACE,kCAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAGA;EACE,wBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;AAAF","sourcesContent":[".arrowBack {\n  width: 40px;\n  height: 40px;\n  background: #eceff2;\n  border: none;\n  border-radius: 6px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n\n  img {\n    width: 5.83px;\n    height: 11.67px;\n  }\n}\n\n.responsible {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-end;\n  font-family: 'Open Sans';\n  font-style: normal;\n  font-size: 13px;\n  line-height: 140%;\n  color: #333333;\n}\n\n.priority {\n  font-family: 'Open Sans Semi Bold';\n  font-style: normal;\n  font-size: 18px;\n  line-height: 116%;\n  color: #333333;\n}\n\n.secondary {\n  font-family: 'Open Sans';\n  font-style: normal;\n  font-size: 14px;\n  line-height: 140%;\n  color: #919ca7;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrowBack": `styles_arrowBack__zgnIu`,
	"responsible": `styles_responsible__cHh3h`,
	"priority": `styles_priority__JgoBj`,
	"secondary": `styles_secondary__DZnxM`
};
export default ___CSS_LOADER_EXPORT___;
