import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { OptionsProps } from 'utils/ts/interfaces';
import { Select } from 'components/Select';

interface FilterProps {
  name: string;
  value: string;
  options: OptionsProps[];
  label: string;
}

export const Filter = ({ value, options, label, name }: FilterProps) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const sendChanges = (val: string) => {
    let queries: any = { [name]: val, page: 1 };
    const currentParams = Object.fromEntries(searchParams);

    setSearchParams({ ...currentParams, ...queries });
  };

  return <Select options={options} active={value} onChange={sendChanges} label={label} />;
};
