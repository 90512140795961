import { statuses } from './constants';
import {
  AffordIcon,
  CancelIcon,
  ConfirmIcon,
  DocumentIcon,
  PauseIcon,
  PrepaymentIcon,
  ProcessIcon,
  SuccessIcon,
  SupplyIcon,
  WarningIcon,
} from 'assets/icons/statuses/';

interface IconsCollectionProps {
  [name: string]: JSX.Element;
}

export const iconsCollection: IconsCollectionProps = {
  [statuses[7]]: <AffordIcon />,
  [statuses[3]]: <SuccessIcon />,
  [statuses[8]]: <CancelIcon />,
  [statuses[4]]: <ConfirmIcon />,
  [statuses[2]]: <DocumentIcon />,
  [statuses[9]]: <PauseIcon />,
  [statuses[5]]: <PrepaymentIcon />,
  [statuses[1]]: <ProcessIcon />,
  [statuses[6]]: <SupplyIcon />,
  [statuses[0]]: <WarningIcon />,
};
