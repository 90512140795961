import styled from 'styled-components';

export const applicationInfoStyles = {
  Container: styled.div`
    padding: 20px 30px;
    border-bottom: 1px solid #eceff2;
  `,
  Cards: styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    gap: 16px;
  `,
  Comment: styled.div`
    width: 100%;
    background: #eceff2;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
  `,
};
