import styled from 'styled-components';

export const dynamicIcon = {
  Wrapper: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 16px;
    margin-right: 6px;
    stroke: rgba(178, 184, 191, 1);
  `,
};
