import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MainLayout } from 'components/MainLayout';
import { refreshToken } from 'utils/requests';
import 'styles/globals.scss';
import './global.scss';

const theme = createMuiTheme();

export const App = () => {
  useEffect(() => {
    const minute = 60000;
    setInterval(refreshToken, minute * 60);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <CssBaseline />
        <MainLayout />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
