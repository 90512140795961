import { redirect } from 'react-router-dom';
import axios from 'axios';
import { baseApiUrl } from './constants';
import { UrlProps } from './ts/interfaces';
import {
  getTokenFromStorage,
  getTokensFromStorage,
  setTokenFromStorage,
} from './localStorage';

interface RequestProps {
  method: string;
  data?: any;
}

interface SimpleRequestProps extends RequestProps {
  headers: {
    Authorization: string;
  };
  validateStatus: (status: number) => boolean;
}

export const refreshToken = (forNewRequest: {
  url: string;
  config: SimpleRequestProps;
}) => {
  const tokens = getTokensFromStorage();
  const url = `${baseApiUrl}auth/token/refresh/`;

  if (tokens) {
    const config = {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      data: { refresh: tokens.refresh },
      validateStatus: (status: number) => status < 500,
    };

    axios(url, config).then(res => {
      const { status, data } = res;
      if (status === 200) {
        setTokenFromStorage(data);
        if (forNewRequest) {
          return simpleRequest(forNewRequest.url, forNewRequest.config);
        }
      } else {
        redirect('/login');
      }
    });
  }
};

export const createUrl = ({
  content,
  activeTab = '',
  status = '',
  query = '',
}: UrlProps) => {
  const url = `${baseApiUrl}dashboard/${content}/${status}${activeTab}${
    query ? query : ''
  }`;
  return url;
};

export const simpleRequest = (url: string, config: SimpleRequestProps) =>
  axios(url, config).then(res => {
    if (res.status === 401) {
      return refreshToken({ url, config });
    }
    return res;
  });

export const sendRequest = (url: string, params?: RequestProps) => {
  let config = {
    method: params ? params.method : 'GET',
    headers: {
      Authorization: `Bearer ${getTokenFromStorage()}`,
    },
    validateStatus: (status: number) => status < 500,
  };

  if (params?.data) {
    // @ts-ignore
    config = { ...config, data: params.data };
  }

  return simpleRequest(url, config);
};

export const getContent = (props: UrlProps) => {
  const url = createUrl(props);
  return sendRequest(url);
};
