import styled from 'styled-components';

export const tabsStyles = {
  OneTab: styled.button`
    outline: none;
    border: none;
    position: relative;
    background: transparent;
    padding-bottom: 14px;
    cursor: pointer;
    border-bottom: transparent solid 2px;

    span {
      font-family: 'Open Sans Semi Bold';
      font-style: normal;
      font-size: 14px;
      line-height: 140%;
      color: #919ca7;
    }
  `,
  Container: styled.div`
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #eceff2;
    padding-bottom: 0px !important;
    gap: 30px;
  `,
};
