import { useEffect, useState, MouseEvent } from 'react';
import InputMask from 'react-input-mask';
import {
  Box,
  Button,
  IconButton,
  FormHelperText,
  FormControl,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
} from '@mui/material';
import { sendRequest, baseApiUrl, getValueFromArray, addedErrorOnField } from 'utils';
import { Eye, HiddenEye } from 'assets/icons';
import { CloseIcon } from 'assets/icons';
import styles from './styles.module.scss';
import { Input, Error } from './styled';
import { InputDataProps } from 'utils/ts/interfaces';
import { Select } from 'components/Select';

interface RecordModalProps {
  title: string;
  show: boolean;
  toggleShow: (show: boolean) => void;
  inputs: InputDataProps[];
  selectEvent?: () => void;
  onSave?: (data: any) => void;
  endpoint: string;
  method: 'POST' | 'PUT' | 'PATCH';
}

const {
  form,
  wrapper,
  close,
  icon,
  header,
  headline,
  inputWrapper,
  input,
  actions,
  button,
  body,
  helper,
} = styles;

export const RecordModal = ({
  title,
  show,
  toggleShow,
  inputs,
  selectEvent,
  onSave,
  method,
  endpoint,
}: RecordModalProps) => {
  const [inputProps, setInputProps] = useState(inputs);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const url = `${baseApiUrl}dashboard${endpoint}`;

  useEffect(() => {
    setInputProps(inputs);
  }, [inputs]);

  const closeModal = () => {
    toggleShow(false);
  };

  const sendForm = (e: any) => {
    e.preventDefault();

    const copyInputs = [...inputProps];

    const desiredValues = getValueFromArray(copyInputs);

    let params = {
      method,
      data: desiredValues,
    };

    sendRequest(url, params).then((res: any) => {
      const { data, status } = res;

      if (status >= 200 && status < 300) {
        if (onSave) onSave(data);
        closeModal();
        setInputProps(inputs);
        setError(null);
      } else {
        const { error } = data;
        if (error.details) {
        }
        if (error.details && error.details.length > 0) {
          if (error.details[0].field === 'non_field_errors') {
            setError(
              data.error.details[0].message || 'При отправке запроса возникла ошибка!',
            );
          } else {
            setInputProps(addedErrorOnField(error.details, inputProps));
          }
        } else {
          setError(
            data.error.details[0].message || 'При отправке запроса возникла ошибка!',
          );
        }
      }
    });
  };

  const changeInputValue = (event: any, field: string) => {
    const newInputProps = inputProps.map(item =>
      field === item.field
        ? {
            ...item,
            value:
              typeof event == 'string' || item.type === 'select'
                ? event
                : event.target.value,
            invalid: false,
          }
        : item,
    );

    setInputProps(newInputProps);
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const commonInputProps = {
    inputProps: {
      'aria-label': 'weight',
    },
    className: input,
    fullWidth: true,
  };

  return (
    <Dialog open={show} onClose={closeModal} className={`${form} form-dialog`}>
      <Box component="form" onSubmit={e => sendForm(e)} className={wrapper}>
        <DialogTitle className={header}>
          <div className={headline}>{title}</div>
          {show ? (
            <IconButton aria-label="close" onClick={closeModal} className={close}>
              <CloseIcon className={icon} />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent className={body}>
          {inputProps.map(
            ({
              id,
              type,
              value,
              field,
              mask,
              placeholder,
              helperText,
              options,
              invalid,
              disabled,
              returnOptionId,
              required,
            }) => (
              <>
                {type === 'select' ? (
                  <Select
                    id={id}
                    active={value} // @ts-ignore
                    options={options}
                    placeholder={placeholder}
                    variant="light"
                    onChange={val => changeInputValue(val, field)}
                    onScrollEnd={selectEvent}
                    returnOptionId={returnOptionId}
                    required={required}
                  />
                ) : (
                  <FormControl
                    key={id}
                    variant="outlined"
                    className={`${inputWrapper} ${invalid && styles.invalid}`}
                  >
                    {mask && type === 'tel' ? (
                      <InputMask
                        mask={mask}
                        value={value}
                        onChange={val => changeInputValue(val, field)}
                      >
                        {/* @ts-ignore */}
                        {(inputProps: any) => (
                          <Input
                            {...inputProps}
                            {...commonInputProps}
                            placeholder={placeholder}
                            type={type}
                            disabled={disabled}
                            required={required}
                          />
                        )}
                      </InputMask>
                    ) : (
                      <Input
                        value={value}
                        type={
                          type === 'password'
                            ? showPassword
                              ? 'text'
                              : 'password'
                            : type
                        }
                        onChange={val => changeInputValue(val, field)}
                        placeholder={placeholder}
                        disabled={disabled}
                        {...commonInputProps}
                        required={required}
                        endAdornment={
                          type === 'password' ? (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <HiddenEye /> : <Eye />}
                              </IconButton>
                            </InputAdornment>
                          ) : undefined
                        }
                      />
                    )}

                    <FormHelperText
                      title={helperText}
                      className={helper}
                      id={'helper' + id}
                    >
                      {helperText}
                    </FormHelperText>
                  </FormControl>
                )}
              </>
            ),
          )}
          {error && <Error>{error}</Error>}
        </DialogContent>
        <DialogActions className={actions}>
          <Button type="submit" className={button}>
            Сохранить
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};
