import styled from 'styled-components';

interface PropsStyle {
  show: boolean;
}

export const templateStyles = {
  Container: styled.div``,
  Content: styled.div`
    margin-top: 38px;
  `,
  Title: styled.div`
    font-family: 'Open Sans Semi Bold';
    font-style: normal;
    font-size: 30px;
    line-height: 116%;
    color: #333333;
    visibility: ${({ show }: PropsStyle) => (show ? 'hidden' : 'visible')};
  `,
  Wrapper: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
  `,
  Button: styled.button`
    display: flex;
    align-items: center;
    padding: 6px 16px 6px 8px;
    gap: 6px;
    background: #ffffff;
    border: 1px solid #b2b8bf;
    border-radius: 40px;
    cursor: pointer;
    outline: none;

    svg {
      max-height: 16px;
      width: 16px;
      stroke: #b2b8bf;
    }

    .title {
      display: contents;
      font-family: 'Open Sans Semi Bold';
      font-size: 14px;
      line-height: 116%;
      margin-left: -25px;
      color: #333333;
    }
  `,
};
