import { headerRoutes } from 'assets/data/header';
import { Roles } from 'shared/roles';

export const getNavigation = (role: number) => {
  let nav: { id: number; title: string; route: string }[] = [];

  switch (role) {
    case Roles.MANAGER_ID:
      nav = headerRoutes.manager;
      break;
    case Roles.DIRECTOR_ID:
      nav = headerRoutes.director;
      break;
    case Roles.ADMIN_ID:
      nav = headerRoutes.admin;
      break;
  }

  return nav;
};
