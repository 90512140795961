import styled from 'styled-components';

export const ScreenWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;

  width: 100%;
  max-height: 200px;

  &.slide-enter-active {
    max-height: 200px !important;
  }

  .MuiDropzoneArea-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    border: none;
    min-height: 200px;
    padding: 16px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #eceff2;
  }

  .MuiDropzoneArea-textContainer {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 18px;
    margin: 0;
  }

  .MuiDropzoneArea-text {
    font-family: 'Open Sans Semi Bold';
    font-size: 18px;
    line-height: 20.88px;
    text-align: center;
    color: #919ca7;
    margin: 0;
  }

  .MuiSnackbarContent-root {
    padding: 16px;
    gap: 8px;
    background: #ffffff;
    box-shadow: 0px 1px 25px rgba(0, 0, 0, 0.18);
    border-radius: 10px;
    font-size: 14px;
    line-height: 20px;
    width: 400px;
    flex-wrap: nowrap;

    &.MuiDropzoneSnackbar-errorAlert {
      /* color: red; */
    }
  }

  .MuiDropzoneSnackbar-message {
    font-size: 15px;
    line-height: 19px;
    gap: 8px;
  }

  .MuiSnackbarContent-action {
    position: absolute;
    right: 0;
    top: 0;
    padding: 0;
    margin: 0;

    .MuiIconButton-root {
      padding: 8px;
      /* color: grey; */

      &:hover {
        background: transparent;
        /* color: red; */
      }
    }
  }

  .MuiSnackbarContent-message {
    padding: 0;
    margin-right: 12px;

    svg {
      margin: 0;
    }
  }

  .MuiDropzonePreviewList-root {
    display: none;
  }
`;

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  margin: 0 !important;

  > svg {
    width: 100%;
    height: 100%;
    fill: #919ca7;
  }
`;
