import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../generateState';
import { AccountProps } from 'utils/ts/interfaces';

interface UserProps {
  user: AccountProps | null;
}
const initialState: UserProps = { user: null };

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserData: (state, { payload }: PayloadAction<AccountProps | null>) => {
      state.user = payload;
    },
  },
});

export const { setUserData } = slice.actions;

export const selectCurrentUser = (state: RootState) => state.user;

export default slice.reducer;
