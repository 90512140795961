// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_link__fx3F2 {
  width: 360px;
  padding: 14px 0;
  font-family: "Open Sans Semi Bold";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 116%;
  outline: none;
  border: none;
  background: #49a3a6;
  border-radius: 40px;
  color: #ffffff;
  text-transform: none;
  text-decoration: none;
  text-align: center;
}
.styles_link__fx3F2:hover {
  background: #49a3a6;
}`, "",{"version":3,"sources":["webpack://./src/components/Message404/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,eAAA;EACA,kCAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,mBAAA;EACA,cAAA;EACA,oBAAA;EACA,qBAAA;EACA,kBAAA;AACF;AACE;EACE,mBAAA;AACJ","sourcesContent":[".link {\n  width: 360px;\n  padding: 14px 0;\n  font-family: 'Open Sans Semi Bold';\n  font-style: normal;\n  font-weight: normal;\n  font-size: 14px;\n  line-height: 116%;\n  outline: none;\n  border: none;\n  background: #49a3a6;\n  border-radius: 40px;\n  color: #ffffff;\n  text-transform: none;\n  text-decoration: none;\n  text-align: center;\n\n  &:hover {\n    background: #49a3a6;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"link": `styles_link__fx3F2`
};
export default ___CSS_LOADER_EXPORT___;
