import styled from 'styled-components';

interface PropsStyle {
  template: string;
}

interface PropsTabStyle {
  type: string;
}

export const tableHeaderStyles = {
  OneTab: styled.div`
    font-family: 'Open Sans Semi Bold';
    font-style: normal;
    font-size: 12px;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #b3b8bf;

    ${({ type }: PropsTabStyle) => {
      const val =
        type === 'payments'
          ? `:nth-last-child(-n+2) {
        text-align: right;
      }`
          : type === 'application'
          ? `:last-child {
        text-align: right;
      }`
          : '';
      return val;
    }}
  `,
  Container: styled.div`
    display: grid;
    grid-template-columns: ${({ template }: PropsStyle) => template};
    grid-area: header;
    padding: 16px;
    align-items: center;
    border-bottom: 1px solid #eceff2;
    gap: 25px;
  `,
};
