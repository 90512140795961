export const headerRoutes = {
  manager: [
    {
      id: 1,
      title: 'Новые заявки',
      route: '/application/new/with-reg',
    },
    {
      id: 2,
      title: 'Заявки в работе',
      route: '/application/underway/with-reg',
    },
    {
      id: 3,
      title: 'Архив заявок',
      route: '/application/finished',
    },
    {
      id: 4,
      title: 'Клиенты',
      route: '/clients/from-agent',
    },
    {
      id: 5,
      title: 'Агенты',
      route: '/agents',
    },
    {
      id: 6,
      title: 'Выплаты',
      route: '/payments',
    },
  ],
  director: [
    {
      id: 1,
      title: 'Новые заявки',
      route: '/application/new/with-reg',
    },
    {
      id: 2,
      title: 'Заявки в работе',
      route: '/application/underway/with-reg',
    },
    {
      id: 3,
      title: 'Архив заявок',
      route: '/application/finished',
    },
    {
      id: 5,
      title: 'Агенты',
      route: '/agents',
    },
    {
      id: 6,
      title: 'Выплаты',
      route: '/payments',
    },
  ],
  admin: [
    {
      id: 1,
      title: 'Новые заявки',
      route: '/application/new/with-reg',
    },
    {
      id: 2,
      title: 'Заявки в работе',
      route: '/application/underway/with-reg',
    },
    {
      id: 3,
      title: 'Архив заявок',
      route: '/application/finished',
    },
    {
      id: 4,
      title: 'Клиенты',
      route: '/clients/from-agent',
    },
    {
      id: 5,
      title: 'Агенты',
      route: '/agents',
    },
    {
      id: 6,
      title: 'Выплаты',
      route: '/payments',
    },
    { id: 7, title: 'Автосалоны', route: '/dealerships' },
    {
      id: 8,
      title: 'Менеджеры',
      route: '/managers',
    },
    {
      id: 9,
      title: 'Директоры',
      route: '/directors',
    },
  ],
};
