// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_notice__Jd3h4 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px 4px 6px;
  gap: 4px;
  border-radius: 20px;
}
.styles_notice__Jd3h4.styles_success__DaKwE {
  background: #f6fff8;
  border: 1px solid #c8e3b7;
  color: rgb(51, 51, 51);
}
.styles_notice__Jd3h4.styles_success__DaKwE svg {
  fill: rgb(90, 175, 38);
}
.styles_notice__Jd3h4 .styles_alert__GU03G {
  color: rgb(51, 51, 51);
  background: transparent;
}`, "",{"version":3,"sources":["webpack://./src/components/MessageNotice/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;EACA,QAAA;EACA,mBAAA;AACF;AACE;EACE,mBAAA;EACA,yBAAA;EACA,sBAAA;AACJ;AACI;EACE,sBAAA;AACN;AAGE;EACE,sBAAA;EACA,uBAAA;AADJ","sourcesContent":[".notice {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  padding: 4px 10px 4px 6px;\n  gap: 4px;\n  border-radius: 20px;\n\n  &.success {\n    background: #f6fff8;\n    border: 1px solid #c8e3b7;\n    color: rgba(51, 51, 51, 1);\n\n    svg {\n      fill: rgba(90, 175, 38, 1);\n    }\n  }\n\n  .alert {\n    color: rgba(51, 51, 51, 1);\n    background: transparent;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notice": `styles_notice__Jd3h4`,
	"success": `styles_success__DaKwE`,
	"alert": `styles_alert__GU03G`
};
export default ___CSS_LOADER_EXPORT___;
