import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCountNewApp } from 'store/slices/countNewApp';
import { setMainLoader } from 'store/slices/mainLoader';
import { determinePageNumber } from 'utils';
import { getContent } from 'utils/requests';
import { UrlProps } from 'utils/ts/interfaces';
import { table } from 'assets/data/payments';
import { Table } from 'components/Table';
import { Pagination } from 'components/Pagination';
import { Loader } from 'components/Loader';
import { PageTemplate } from 'components/PageTemplate';
import { Content } from './styles';

export const Payments = () => {
  const [content, setContent] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [countPages, setCountPages] = useState(0);
  const dispatch = useDispatch();
  const pageName = 'payments';
  const locationQuery = useLocation().search;
  const pageNumber = determinePageNumber(locationQuery) || 1;
  let params: UrlProps = {
    content: pageName,
    query: locationQuery,
  };

  const sendRequest = (config: UrlProps) => {
    setLoading(true);
    getContent(config).then(({ data }: any) => {
      const { result, pages, countNewApp } = data;
      setCountPages(pages);
      setTimeout(() => {
        setContent(result);
        setLoading(false);
      }, 250);
      dispatch(setMainLoader(false));
      if (countNewApp !== undefined) dispatch(setCountNewApp(countNewApp));
    });
  };

  useEffect(() => {
    setLoading(true);
    params.query = locationQuery;
    sendRequest(params);
  }, [pageNumber]);

  return (
    <PageTemplate title="Выплаты">
      <Content>
        <Loader active={loading}>
          <Table content={content} titles={table} type={pageName} />
        </Loader>
        <Pagination count={countPages} />
      </Content>
    </PageTemplate>
  );
};
