import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCountNewApp } from 'store/slices/countNewApp';
import { setMainLoader } from 'store/slices/mainLoader';
import { determinePageNumber } from 'utils';
import { getContent } from 'utils/requests';
import { UrlProps } from 'utils/ts/interfaces';
import { table } from 'assets/data/agents';
import { PageTemplate } from 'components/PageTemplate';
import { Table } from 'components/Table';
import { Pagination } from 'components/Pagination';
import { Loader } from 'components/Loader';
import { RecordModal } from 'components/RecordModal/RecordModal';
import { profileAgent } from 'assets/data/forms';
import { useGetDealershipsListMutation, useDealershipsCheck, useUserCheck } from 'hooks';
import { setDealerships } from 'store/slices/dealerships';
import { Roles } from 'shared/roles';
import { Content } from './styles';

export const Agents = () => {
  const [content, setContent] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [countPages, setCountPages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [inputs, setInputs] = useState(profileAgent);
  const [recordId, setRecordId] = useState<null | number>(null);
  const dispatch = useDispatch();
  const dealerships = useDealershipsCheck();
  const [getDealershipsList] = useGetDealershipsListMutation();
  const user = useUserCheck();
  const locationQuery = useLocation().search;
  const pageNumber = determinePageNumber(locationQuery) || 1;
  const pageName = 'agents';
  let params: UrlProps = {
    content: pageName,
    query: locationQuery,
  };

  const sendRequest = (config: UrlProps) => {
    setLoading(true);
    getContent(config).then(({ data }: any) => {
      const { result, pages, countNewApp } = data;
      setCountPages(pages);
      setTimeout(() => {
        setContent(result);
        setLoading(false);
      }, 250);
      dispatch(setMainLoader(false));
      if (countNewApp !== undefined) dispatch(setCountNewApp(countNewApp));
    });
  };

  const editItem = (data: any) => {
    const newInputs = profileAgent.map(item => {
      let newItem;

      if (item.field === 'group') {
        newItem = {
          ...item,
          value: data[item.field]
            ? { id: data[item.field].id, value: data[item.field].name }
            : '',
          options: dealerships.list,
        };
      } else {
        newItem = { ...item, value: data[item.field], disabled: true };
      }

      return newItem;
    });
    setRecordId(data.id);
    // @ts-ignore
    setInputs(newInputs);
    setShowModal(true);
  };

  const getDealerships = () => {
    if (dealerships.list.length < dealerships.count || dealerships.list.length === 0) {
      getDealershipsList({ queries: `?offset=${dealerships.list.length}&limit=30` })
        .unwrap()
        .then((res: any) => {
          dispatch(
            setDealerships({
              list: [...dealerships.list, ...res.results],
              count: res.count,
            }),
          );
        });
    }
  };

  const changeContent = (data: any) => {
    let newContent = [...content];
    const index = content.findIndex(item => item.id === data.id);
    newContent[index] = data;
    setContent(newContent);
  };

  useEffect(() => {
    setLoading(true);

    if (dealerships.list.length === 0) {
      getDealerships();
    }

    params.query = locationQuery;
    sendRequest(params);
  }, [pageNumber]);

  useEffect(() => {
    const newInputs = inputs.map(item => {
      let newItem;

      if (item.field === 'group') {
        newItem = { ...item, options: dealerships.list };
      } else {
        newItem = { ...item };
      }

      return newItem;
    });

    setInputs(newInputs);
  }, [dealerships]);

  return (
    <PageTemplate title="Агенты">
      <Content>
        <Loader active={loading}>
          <Table
            content={content}
            titles={table}
            type={pageName}
            isEdit={user?.role === Roles.ADMIN_ID}
            onEdit={editItem}
          />
        </Loader>
        <Pagination count={countPages} />
      </Content>
      <RecordModal
        title="Редактирование записи"
        show={showModal}
        inputs={inputs}
        toggleShow={setShowModal}
        selectEvent={() => getDealerships()}
        endpoint={`/agents/${recordId}/`}
        method="PATCH"
        onSave={changeContent}
      />
    </PageTemplate>
  );
};
