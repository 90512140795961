import styled from '@emotion/styled';

interface PropsStyle {
  active: boolean;
}

export const loadingStyles = {
  Container: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    background-color: white;
    border-radius: 12px;
    ${({ active }: PropsStyle) =>
      active
        ? 'transition: all 0.25s ease-in-out; opacity: 1; z-index: 1;'
        : 'opacity: 0; z-index: -2;'}

    span {
      position: absolute;
      color: #b2b8bf;
    }
  `,
  Content: styled.div`
    min-height: 30vh;
    visibility: ${({ active }: PropsStyle) => (active ? 'hidden' : 'visible')};
  `,
};
