import { DetailInfoProps } from 'utils/ts/interfaces';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sendRequest, baseApiUrl } from 'utils';
import { setDetails } from 'store/slices/applicationDetails';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { statuses, getFileNameFromUrl } from 'utils';
import { DetailInfoItem } from 'components/DetailInfoItem/DetailInfoItem';
import { personalInfoStyles } from './styles';
import { UploadScreen } from 'components/UploadScreen';
import { useState } from 'react';
import { FileObject } from 'material-ui-dropzone';
import { FileItem } from 'components/FileItem';
import { useAppDetailsCheck } from 'hooks';

const { Block, Container, Contacts, Text, Error } = personalInfoStyles;

interface PersonalInfoProps extends DetailInfoProps {
  isManager: boolean;
}

export const PersonalInfo = ({
  status,
  fullName,
  phoneNumber,
  companyName,
  email,
  uin,
  client,
  agent,
  comment,
  payment,
  paymentSchedule,
  isManager,
}: PersonalInfoProps) => {
  const paymentStatus = payment?.status ? payment.status : null;
  const agentInfo = agent && agent.numberType ? agent.numberType : null;
  const numberType = client && client.numberType ? client.numberType : agentInfo;
  const dispatch = useDispatch();
  const [file, setFile] = useState<FileObject[]>([]);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileName = paymentSchedule && getFileNameFromUrl(paymentSchedule);
  const url = `${baseApiUrl}dashboard/application/${id}/schedule/`;
  const sectionsInfo = useAppDetailsCheck();

  const isEdit =
    ((status === statuses[7] && agent !== null && paymentStatus !== 'Выплачено') ||
      (status !== statuses[9] &&
        status !== statuses[0] &&
        status !== statuses[7] &&
        status !== statuses[8])) &&
    isManager;

  const fileChange = (files: any) => {
    setFile([...files]);
    setLoading(true);
    const formData = new FormData();
    formData.append('paymentSchedule', files[0].file, files[0].file.name);

    let params = {
      method: 'PATCH',
      data: formData,
    };

    sendRequest(url, params).then((res: any) => {
      const { data, status } = res;
      setFile([]);
      if (data.error || status > 300) {
        setError(
          data.error.message ||
            'При отправке файла на сервер, что-то пошло не так. Попробуйте снова!',
        );
      } else {
        dispatch(
          setDetails({
            ...sectionsInfo,
            paymentSchedule: data.paymentSchedule,
          }),
        );
        setLoading(false);
      }
    });
  };

  const removeFile = () => {
    let params = {
      method: 'PATCH',
      data: { paymentSchedule: null },
    };
    sendRequest(url, params).then((res: any) => {
      const { data, status } = res;
      if (status > 300) {
        setError('При попытке удалить файл, что-то пошло не так. Попробуйте снова!');
      } else {
        dispatch(
          setDetails({
            ...sectionsInfo,
            paymentSchedule: data.paymentSchedule,
          }),
        );
      }
    });
  };

  return (
    <Container>
      <Contacts>
        <DetailInfoItem edit={isEdit} name="Имя" fieldName="fullName" value={fullName} />
        {(email || isEdit) && (
          <DetailInfoItem
            edit={isEdit}
            name="Почта"
            fieldName="email"
            inputType="email"
            value={email ? email : ''}
          />
        )}
        {(phoneNumber || isEdit) && (
          <DetailInfoItem
            edit={isEdit}
            name="Телефон"
            fieldName="phoneNumber"
            inputType="tel"
            mask="+7 999 999 99 99"
            value={phoneNumber}
          />
        )}
        {(uin || isEdit) && (
          <DetailInfoItem edit={isEdit} name="ИНН" fieldName="uin" value={uin} />
        )}
        {(companyName || isEdit) && (
          <DetailInfoItem
            edit={isEdit}
            name="Компания"
            fieldName="companyName"
            value={companyName}
          />
        )}
        {numberType && (
          <DetailInfoItem
            edit={false}
            name="Удобный вариант связи"
            fieldName="numberType"
            value={numberType}
          />
        )}
      </Contacts>
      <Block>
        <span>Комментарий</span>
        {comment ? <Text>{comment}</Text> : null}
      </Block>
      <Block>
        <span>График платежей</span>
        {error && <Error>{error}</Error>}
        <SwitchTransition>
          {paymentSchedule || file.length > 0 ? (
            <CSSTransition key="file" classNames="fade" timeout={250}>
              <FileItem
                name={fileName || file[0].file.name}
                id={1}
                url={paymentSchedule || ''}
                loader={loading}
                onRemove={removeFile}
              />
            </CSSTransition>
          ) : (
            <CSSTransition key="upload" classNames="slide" timeout={250}>
              {isEdit ? (
                <UploadScreen
                  files={file}
                  onChange={fileChange}
                  maxCount={1}
                  accept={['.pdf', '.doc', '.docx', '.jpg', '.jpeg', '.png', '.xlsx']}
                />
              ) : (
                <Text>—</Text>
              )}
            </CSSTransition>
          )}
        </SwitchTransition>
      </Block>
    </Container>
  );
};
