import dateFormat from 'dateformat';
import { dateStyles } from './styles';

const { Date } = dateStyles;

interface DataProps {
  date: string;
}

export const DateFormat = ({ date = '' }: DataProps) => (
  <Date>{dateFormat(date, 'dd.mm.yyyy')}</Date>
);
