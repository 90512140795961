import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Message404 } from 'components/Message404';

export const NotFound = () => {
  let location = useLocation();

  useEffect(() => {
    console.log(location);
  });

  return (
    <>
      <Message404 />
    </>
  );
};
