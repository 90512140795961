import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { tabsStyles } from './styles';

interface TabsItemProps {
  id: number;
  title: string;
  param: string;
}

interface TabsProps {
  children: JSX.Element | JSX.Element[] | null;
  tabs: TabsItemProps[];
  changeParamUrl: (param: string) => void;
  commonUrl: string;
}

const { Container, OneTab } = tabsStyles;

export const Tabs = ({ children, tabs, changeParamUrl, commonUrl }: TabsProps) => {
  const location = useLocation();
  const pathname = location.pathname;
  const [active, setActive] = useState<any>(1);

  const defineActive = (path: string) => {
    let splittedPath = path.split('/');
    const lastItem = splittedPath.at(-1);
    const tab = tabs.filter(({ param }) => lastItem === param);
    const activeTab = tab.length ? tab[0] : tabs[0];
    setActive(activeTab.id);
    return activeTab;
  };

  const tabToggle = (path: string) => {
    const element = defineActive(path);
    changeParamUrl(element.param);
  };

  useEffect(() => {
    if (tabs.length) {
      defineActive(pathname);
    }
  }, []);

  useEffect(() => {
    if (tabs.length) {
      tabToggle(pathname);
    }
  }, [pathname]);

  return (
    <>
      {tabs.length > 0 && (
        <Container>
          {tabs.map(({ id, title, param }) => (
            <Link key={id} to={`${commonUrl}/${param}`}>
              <OneTab className={`${active === id && 'tabs-active'}`}>
                <span>{title}</span>
              </OneTab>
            </Link>
          ))}
        </Container>
      )}
      {children}
    </>
  );
};
