import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../generateState';
import { OptionsProps } from 'utils/ts/interfaces';

interface DealershipsProps {
  dealerships: {
    list: OptionsProps[];
    count: number;
  };
}
const initialState: DealershipsProps = { dealerships: { list: [], count: 0 } };

const slice = createSlice({
  name: 'dealerships',
  initialState,
  reducers: {
    setDealerships: (
      state,
      { payload }: PayloadAction<{ list: OptionsProps[]; count: number }>,
    ) => {
      state.dealerships = payload;
    },
    setDealershipsList: (state, { payload }: PayloadAction<OptionsProps[]>) => {
      state.dealerships = { ...state.dealerships, list: payload };
    },
    setDealershipsCount: (state, { payload }: PayloadAction<number>) => {
      state.dealerships = { ...state.dealerships, count: payload };
    },
  },
});

export const { setDealerships, setDealershipsList, setDealershipsCount } = slice.actions;

export const selectDealerships = (state: RootState) => {
  return state.dealerships;
};

export default slice.reducer;
