import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setDetails } from 'store/slices/applicationDetails';
import { setCountNewApp } from 'store/slices/countNewApp';
import { Statuses } from 'utils/ts/customTypes';
import { sendRequest, baseApiUrl, statuses } from 'utils';
import { PaymentItemProps, DetailInfoProps } from 'utils/ts/interfaces';
import { DetailInfoItem } from 'components/DetailInfoItem/DetailInfoItem';
import avatar from 'assets/icons/avatar.svg';
import { RubleIcon, CheckIcon } from 'assets/icons';
import { agentCardStyles } from './styles';
import styles from './styles.module.scss';
import { Button } from '@mui/material';
import { useParams } from 'react-router-dom';

const { Agent, AgentAvatar, AgentEmail, AgentNumber, AgentName, AgentTitle } =
  agentCardStyles;

interface AgentCardProps extends DetailInfoProps {
  isHavingsUser: boolean;
  isManager: boolean;
  status: Statuses;
}

export const AgentCard = ({
  status,
  isHavingsUser,
  isManager,
  ...props
}: AgentCardProps) => {
  const {
    //@ts-ignore
    agent: { email, fullName, phoneNumber, group },
    payment,
  } = props;
  const [reward, setReward] = useState<PaymentItemProps | null>(payment);
  const { id } = useParams();
  const dispatch = useDispatch();
  const url = `${baseApiUrl}dashboard/application/${id}/payment/`;
  const isEdit =
    status !== statuses[0] &&
    status !== statuses[8] &&
    status !== statuses[9] &&
    isHavingsUser &&
    isManager;
  const { button, paid, priority, secondary } = styles;

  useEffect(() => {
    setReward(reward);
  }, []);

  useEffect(() => {
    setReward(payment);
  }, [payment]);

  const payReward = () => {
    const params = {
      method: 'PUT',
      data: {
        amount: reward?.amount ? reward.amount : 0,
        status: 'Выплачено',
      },
    };

    sendRequest(url, params).then(({ data }: any) => {
      if (data && props.payment) {
        const { countNewApp, ...other } = data;
        const newPayment = { ...props.payment, ...other };
        dispatch(setDetails({ ...props, status, payment: newPayment }));
        if (countNewApp !== undefined) dispatch(setCountNewApp(countNewApp));
      }
    });
  };

  return (
    <Agent>
      <AgentAvatar>
        <img src={avatar} alt="" />
      </AgentAvatar>
      <AgentTitle>Агент</AgentTitle>
      <AgentName>{fullName}</AgentName>
      {group && (
        <>
          <AgentTitle>Автосалон</AgentTitle>
          <AgentName>{group.name}</AgentName>
        </>
      )}
      <AgentNumber>{phoneNumber}</AgentNumber>
      <AgentEmail>{email}</AgentEmail>
      {reward?.status !== 'Выплачено' ? (
        <DetailInfoItem
          name="Вознаграждение"
          value={reward?.amount ? reward.amount : ''}
          fieldName="amount"
          edit={isEdit}
          inputType="payment"
          placeholder="Нет данных"
          filling={true}
        />
      ) : (
        <div className={paid}>
          <AgentTitle>Вознаграждение</AgentTitle>
          <div className={priority}>
            {reward.amount}
            <RubleIcon />
          </div>
          <div className={secondary}>
            <CheckIcon />
            Выплачено
          </div>
        </div>
      )}
      {status === 'Выдано' && reward?.status !== 'Выплачено' ? (
        <Button className={button} onClick={payReward}>
          Выплатить
        </Button>
      ) : null}
    </Agent>
  );
};
