import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from 'hooks/useApiRequest';
import userReducer from './slices/user';
import activeStatusReducer from './slices/activeStatus';
import applicationDetailsReducer from './slices/applicationDetails';
import countNewAppReducer from './slices/countNewApp';
import mainLoaderReducer from './slices/mainLoader';
import filtersReducer from './slices/filters';
import dealershipsReducer from './slices/dealerships';

const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    user: userReducer,
    applicationDetails: applicationDetailsReducer,
    activeStatus: activeStatusReducer,
    countNewApp: countNewAppReducer,
    mainLoader: mainLoaderReducer,
    filters: filtersReducer,
    dealerships: dealershipsReducer,
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
