import { Star } from 'assets/icons';
import { ratingStyles } from './styles';

interface RatingProps {
  rating: number | null;
}

const { Stars, Icon } = ratingStyles;

export const Rating = ({ rating }: RatingProps) => {
  const stars = () => {
    var indents = [];
    for (var i = 0; i < 5; i++) {
      const active = rating && i < rating ? true : false;
      indents.push(
        <Icon key={i} active={active}>
          <Star />
        </Icon>,
      );
    }
    return indents;
  };
  return <Stars title={rating ? rating : 0}>{stars()}</Stars>;
};
