import { useState, FormEvent, MouseEvent, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  IconButton,
  FormHelperText,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { useLoginMutation, useGetUserMutation } from 'hooks';
import { setTokenFromStorage } from 'utils';
import { setUserData } from 'store/slices/user';
import { setCountNewApp } from 'store/slices/countNewApp';
import loginStyles from './styles';
import car from 'assets/images/car.svg';
import { Eye, HiddenEye } from 'assets/icons';
import styles from './styles.module.scss';

const { Container, Title, ImageContainer, Content, ButtonContainer, Label } = loginStyles;
const {
  form,
  inputWrapper,
  input,
  button,
  helper,
  invalid,
  focused,
  disabled,
  imageWrapper,
} = styles;

export const Login = () => {
  const fields = { password: '', email: '' };
  const [values, setValues] = useState(fields);
  const [isInvalid, setIsInvalid] = useState(false);
  const [helperText, setHelperText] = useState('');
  const idForHelperText = 'outlined-helper-text-';
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const [login] = useLoginMutation();
  const [getUser] = useGetUserMutation();
  const navigate = useNavigate();

  const giveAnError = (error: any) => {
    const { message } = error;
    setIsInvalid(true);
    setHelperText(message);
  };

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const changeInputValue = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = event.target;
    setIsInvalid(false);
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const body = {
      ...values,
    };

    login(body)
      .unwrap()
      .then((res: any) => {
        const { access, refresh } = res;
        if (access) {
          setIsInvalid(false);
          setTokenFromStorage({ access, refresh });
          getUser()
            .unwrap()
            .then((data: any) => {
              const { countNewApp, ...user } = data;
              dispatch(setUserData(user));
              dispatch(setCountNewApp(countNewApp));
              navigate('/application/new');
            });
        }
      })
      .catch((error: any) => giveAnError(error.data.error.details[0]));
  };

  return (
    <Container>
      <ImageContainer>
        <div className={imageWrapper}>
          <img src={car} />
        </div>
      </ImageContainer>
      <Content>
        <Title>Вход</Title>
        <Box component="form" className={form} onSubmit={e => handleSubmit(e)}>
          <FormControl
            variant="outlined"
            className={`${inputWrapper} ${isInvalid ? invalid : ''} login-form`}
          >
            <Label htmlFor="outlined-adornment-email">Почта</Label>
            <OutlinedInput
              id="outlined-adornment-email"
              name="email"
              placeholder="Почта"
              type="email"
              autoFocus
              fullWidth
              required
              className={`${input} login-form`}
              value={values.email}
              onChange={changeInputValue}
            />
          </FormControl>
          <FormControl
            variant="outlined"
            className={`${inputWrapper} ${isInvalid ? `${invalid} invalid` : ''}`}
          >
            <Label htmlFor="outlined-adornment-password">Пароль</Label>
            <OutlinedInput
              id="outlined-adornment-password"
              aria-describedby={idForHelperText + 'password'}
              name="password"
              placeholder="Пароль"
              fullWidth
              required
              className={`${input} login-form`}
              type={showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={changeInputValue}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <HiddenEye /> : <Eye />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <FormHelperText
              title={helperText}
              className={helper}
              id={idForHelperText + 'password'}
            >
              {helperText}
            </FormHelperText>
          </FormControl>
          <ButtonContainer>
            <Button
              type="submit"
              className={`${button} ${
                values.email.length && values.password.length ? '' : disabled
              }`}
            >
              Войти
            </Button>
          </ButtonContainer>
        </Box>
      </Content>
    </Container>
  );
};
