import { Suspense, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useUserCheck, useAuthCheck, useGetUserMutation } from 'hooks';
import { setUserData } from 'store/slices/user';
import { setCountNewApp } from 'store/slices/countNewApp';
import { Loader } from 'components/Loader';
import { Layout } from './Layout';
import { Login } from 'pages';
import paths from './paths';

const PackageLocation = () => {
  const user = useUserCheck();
  const dispatch = useDispatch();
  const [getUser] = useGetUserMutation();
  const [isLoaded, setIsLoaded] = useState(false);
  const loader = useAuthCheck;

  useEffect(() => {
    if (!user) {
      loader(getUser).then(response => {
        if (response) {
          const { countNewApp, ...user } = response;
          dispatch(setUserData(user));
          dispatch(setCountNewApp(countNewApp));
        }
        setIsLoaded(true);
      });
    }
  }, []);

  return (
    <>
      {isLoaded && (
        <Routes>
          <Route key={1 + 'login'} path="login" element={<Login />} />
          <Route element={<Layout />}>
            {paths.map(({ id, path, element }) => (
              <Route key={id} path={path} element={element} />
            ))}
          </Route>
        </Routes>
      )}
    </>
  );
};

export const Navigation = () => {
  return (
    <Suspense fallback={<Loader active={true} />}>
      <PackageLocation />
    </Suspense>
  );
};
