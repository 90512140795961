import { useCountNewApp } from 'hooks';
import styles from './styles.module.scss';

interface NoticeProps {
  className?: string;
}

export const Notice = (props: NoticeProps) => {
  const count = useCountNewApp();
  const { notice, number, active } = styles;

  return (
    <div className={`${notice} ${count ? active : null}`}>
      <div className={number}>{count}</div>
    </div>
  );
};
