import { TableHeaderItemProps, TableListItemProps } from 'utils/ts/interfaces';
import { TableType } from 'utils/ts/customTypes';
import { getGridTemplate } from 'utils';
import { TableHeader } from './TableHeader';
import { TableItem } from './TableItem';
import { tableStyles } from './styles';

interface TableProps {
  titles: TableHeaderItemProps[];
  content: TableListItemProps[] | null;
  type: TableType;
  activeTab?: string;
  isEdit?: boolean;
  onEdit?: (val: any) => void;
}

const { Container, TableBody } = tableStyles;

export const Table = ({
  titles,
  content,
  type,
  activeTab,
  isEdit,
  onEdit,
}: TableProps) => {
  const countColumn = titles.length;
  const gridTemplate = getGridTemplate({
    countColumn,
    type,
    tab: activeTab,
    edited: isEdit,
  });

  return (
    <Container>
      <TableHeader list={titles} type={type} gridTemplate={gridTemplate} />
      <TableBody>
        {content ? (
          <>
            {content.map(({ id, ...item }) => (
              <TableItem
                key={id}
                id={id}
                type={type}
                {...item}
                gridTemplate={gridTemplate}
                countColumn={countColumn}
                isEdit={isEdit}
                onEdit={onEdit}
              />
            ))}
          </>
        ) : (
          <div className="no-data">Нет данных</div>
        )}
      </TableBody>
    </Container>
  );
};
