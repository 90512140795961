import styled from 'styled-components';
import { Link } from 'react-router-dom';

interface PropsStyle {
  template: string;
}

interface PropsTabStyle {
  type: string;
}
export const LinkContainer = styled(Link)`
  text-decoration: none;
  border-bottom: 1px solid #eceff2;
  &:last-child {
    border-bottom: none;
  }
`;

export const Container = styled.div`
  text-decoration: none;
  border-bottom: 1px solid #eceff2;
  &:last-child {
    border-bottom: none;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: ${({ template }: PropsStyle) => template};
  padding: 14px 16px;
  gap: 25px;
  position: relative;
`;
export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2px;
  font-family: 'Open Sans';
  font-style: normal;
  font-size: 13px;
  line-height: 140%;
  color: #333333;
  width: 100%;
  overflow: hidden;

  div {
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 13px;
    line-height: 140%;
    color: #b3b8bf;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span svg {
    height: 12px;
    margin-left: 2px;
  }

  &.button {
    cursor: pointer;
  }

  ${({ type }: PropsTabStyle) =>
    type === 'payments'
      ? `
        :nth-last-child(-n+2) {
          span {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            text-align: right;
          }
        }`
      : ''}
`;
export const Status = styled.div`
  gap: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
