// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_notice__\\+C\\+2b {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0;
  height: 18px;
  background: #df5959;
  border-radius: 50%;
  margin-left: 4px;
  transform: scale(0);
  opacity: 0;
  transition: all 0.25s ease-in-out;
}
.styles_notice__\\+C\\+2b.styles_active__LxQg0 {
  opacity: 1;
  transform: scale(1);
  width: 18px;
}
.styles_notice__\\+C\\+2b .styles_number__EspA1 {
  font-family: "Open Sans Semi Bold";
  font-size: 10px;
  text-align: center;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/components/Notice/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,QAAA;EACA,YAAA;EACA,mBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,UAAA;EACA,iCAAA;AACF;AACE;EACE,UAAA;EACA,mBAAA;EACA,WAAA;AACJ;AAEE;EACE,kCAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;AAAJ","sourcesContent":[".notice {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  width: 0;\n  height: 18px;\n  background: #df5959;\n  border-radius: 50%;\n  margin-left: 4px;\n  transform: scale(0);\n  opacity: 0;\n  transition: all 0.25s ease-in-out;\n\n  &.active {\n    opacity: 1;\n    transform: scale(1);\n    width: 18px;\n  }\n\n  .number {\n    font-family: 'Open Sans Semi Bold';\n    font-size: 10px;\n    text-align: center;\n    color: #ffffff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notice": `styles_notice__+C+2b`,
	"active": `styles_active__LxQg0`,
	"number": `styles_number__EspA1`
};
export default ___CSS_LOADER_EXPORT___;
