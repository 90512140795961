import styled from 'styled-components';

export const applicationBoardStyles = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
  `,
  Content: styled.div`
    border-radius: 12px;
    background: #ffffff;
    padding-bottom: 14px;
    position: relative;
  `,
  FiltersBar: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;

    @media (min-width: 1440px) {
      gap: 30px;
    }
  `,
  FiltersClear: styled.div`
    display: flex;
    justify-content: flex-end;
  `,
  Button: styled.button`
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    color: #b2b8bf;
    font-family: 'Open Sans Semi Bold';
    font-size: 16px;
    font-weight: 500;
  `,
};
