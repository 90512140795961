import styled from 'styled-components';

interface PropsStyle {
  title: number;
}

export const ratingStyles = {
  Stars: styled.div.attrs((props: PropsStyle) => ({
    title: props.title || 0,
  }))`
    display: flex;
    gap: 5px;
    width: 100px;
  `,
  Icon: styled.div`
    svg {
      fill: ${(props: { active: boolean }) =>
        props.active ? 'rgba(237, 221, 79, 1)' : 'rgba(227, 232, 239, 1)'};
    }
  `,
};
