import { statuses } from 'utils';
import { DetailInfoItem } from 'components/DetailInfoItem/DetailInfoItem';
import { applicationInfoStyles } from './styles';

interface ApplicationInfoProps {
  status: string;
  productName: string;
  limitation: number | string;
  cost: number | string;
  advance: number | string;
  managerComment: string | null;
  isHavingsUser: boolean;
  isManager: boolean;
}

const { Container, Cards, Comment } = applicationInfoStyles;

export const ApplicationInfo = (props: ApplicationInfoProps) => {
  const {
    status,
    productName,
    limitation,
    cost,
    advance,
    managerComment,
    isHavingsUser,
    isManager,
  } = props;
  const editableStatuses =
    status !== statuses[0] &&
    status !== statuses[7] &&
    status !== statuses[8] &&
    status !== statuses[9];
  const isEdit = isHavingsUser && editableStatuses && isManager;

  return (
    <Container>
      <DetailInfoItem
        edit={isEdit}
        name="Наименование"
        fieldName="productName"
        value={productName}
      />
      <Cards>
        <div style={{ display: 'flex', gap: 16, justifyContent: 'space-between' }}>
          <DetailInfoItem
            name="Стоимость"
            value={cost ? cost : ''}
            fieldName="cost"
            edit={isEdit}
            placeholder="Нет данных"
            title="Стоимость имущества"
            filling={true}
            mask="999 999 999 999"
          />
          <DetailInfoItem
            name="Срок"
            value={limitation ? limitation : ''}
            fieldName="limitation"
            edit={isEdit}
            placeholder="Нет данных"
            title="Срок лизинга"
            filling={true}
          />
          <DetailInfoItem
            name="Аванс"
            value={advance ? advance : ''}
            fieldName="advance"
            edit={isEdit}
            placeholder="Нет данных"
            filling={true}
          />
        </div>

        {status !== 'Новая' && (
          <Comment>
            <DetailInfoItem
              name="Комментарий менеджера"
              value={managerComment ? managerComment : ''}
              fieldName="managerComment"
              edit={isEdit}
              inputType="textarea"
              placeholder="Введите ваш комментарий"
              title="Комментарий к заявке"
              filling={true}
            />
          </Comment>
        )}
      </Cards>
    </Container>
  );
};
