import { Link } from 'react-router-dom';
import { notFoundStyles } from './styles';
import styles from './styles.module.scss';

const { Wrapper, Decoration } = notFoundStyles;
const { link } = styles;

interface Props {
  error?: any;
}
export const Message404 = ({ error }: Props) => (
  <Wrapper>
    <h2>{`${
      error && error.details ? error.details[0].message : 'Такой страницы не существует'
    }`}</h2>
    <Decoration>{error.status || 404}</Decoration>
    <Link to="/application/new" className={link}>
      Вернуться на главную
    </Link>
  </Wrapper>
);
