// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_layout__EAJiA {
  opacity: 0;
  pointer-events: none;
}
.styles_layout__EAJiA.styles_show__4-i\\+F {
  transition: all 0.25s ease-in-out;
  pointer-events: auto;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/routes/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,oBAAA;AACF;AACE;EACE,iCAAA;EACA,oBAAA;EACA,UAAA;AACJ","sourcesContent":[".layout {\n  opacity: 0;\n  pointer-events: none;\n\n  &.show {\n    transition: all 0.25s ease-in-out;\n    pointer-events: auto;\n    opacity: 1;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `styles_layout__EAJiA`,
	"show": `styles_show__4-i+F`
};
export default ___CSS_LOADER_EXPORT___;
