import styled from 'styled-components';

export const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const Link = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.div`
  font-size: 16px;
  line-height: 22px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #49a3a6;
`;

export const ImageWrapper = styled.div`
  position: relative;

  > svg {
    width: 24px;
    height: 24px;
    fill: #49a3a6;
  }
`;

export const RemoveWrapper = styled.div`
  position: relative;
  cursor: pointer;

  svg {
    width: 10px;
    height: 10px;

    path {
      transition: all 0.2s ease-in-out;
    }
  }

  &:hover {
    svg path {
      stroke: black;
    }
  }
`;
