export { ReactComponent as ArrowLeft } from './arrowleft.svg';
export { ReactComponent as ArrowRight } from './arrowright.svg';
export { ReactComponent as CloseIcon } from './close.svg';
export { ReactComponent as CheckIcon } from './check.svg';
export { ReactComponent as EditIcon } from './edit.svg';
export { ReactComponent as Eye } from './eye.svg';
export { ReactComponent as HiddenEye } from './hidden-eye.svg';
export { ReactComponent as LogoutIcon } from './logout.svg';
export { ReactComponent as RubleIcon } from './ruble.svg';
export { ReactComponent as Setting } from './setting.svg';
export { ReactComponent as Star } from './star.svg';
export { ReactComponent as DocIcon } from './doc.svg';
export { ReactComponent as SearchIcon } from './search.svg';
