export const titles = [
  {
    id: 1,
    title: 'Новые заявки',
    route: '/application/new',
  },
  {
    id: 2,
    title: 'Заявки в работе',
    route: '/application/underway',
  },
  {
    id: 3,
    title: 'Архив заявок',
    route: '/application/finished',
  },
  {
    id: 4,
    title: 'Клиенты',
    route: '/clients',
  },
  {
    id: 5,
    title: 'Агенты',
    route: '/agents',
  },
  {
    id: 6,
    title: 'Выплаты',
    route: '/payments',
  },
  {
    id: 7,
    title: 'Редактировать данные',
    route: '/settings',
  },
  {
    id: 8,
    title: 'Заявка',
    route: '/application',
  },
  {
    id: 9,
    title: 'Авторизация',
    route: '/login',
  },
];
