import styled from 'styled-components';

export const settingsStyles = {
  Container: styled.div`
    width: 43%;
    height: 75vh;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `,

  Title: styled.div`
    font-family: 'Open Sans Semi Bold';
    font-style: normal;
    font-size: 30px;
    line-height: 116%;
    margin-bottom: 40px;
    color: #333333;
  `,

  Content: styled.div`
    background: #ffffff;
    border-radius: 10px;
    padding: 40px;
  `,

  ButtonContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  `,

  Label: styled.label`
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    line-height: 140%;
    color: #919ca7;
  `,
};
