import styled from 'styled-components';

export const mainLayoutStyles = {
  Container: styled.div`
    min-height: 100vh;
    position: relative;
  `,
  MainContainer: styled.div`
    background: #eceff2;
    min-height: calc(100vh - 70px);
    padding: 40px 135px 75px;
    position: relative;
  `,
};
