import { OutlinedInput, MenuItem, InputLabel, FormControl, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputWrapper = styled(FormControl)`
  margin: 0 !important;
  min-width: unset;

  &:before {
    content: '';
    display: block;
    position: absolute;
    right: 43px;
    top: 1px;
    width: 1px;
    height: 43px;
    background-color: #e3e8ef;
    z-index: 2;
  }

  &.light {
    &:before {
      height: 48px;
      background-color: rgba(178, 184, 191, 1);
    }
  }

  &.withLabel {
    .MuiOutlinedInput-input {
      padding: 6px 43px 6px 12px !important;
      font-family: Open Sans Bold;
      font-size: 14px;
      line-height: 116%;
      transform: translateY(6px);
    }
  }

  .MuiInputLabel-shrink {
    transform: translate(12px, 4px) scale(0.8);
  }
`;

export const Input = styled(OutlinedInput)`
  padding: 0;
  gap: 6px;
  width: 270px;
  height: 45px;
  background: #ffffff;
  border-radius: 10px;
  transition: all 0.25s ease-in-out;

  > div {
    font-size: 14px;
    line-height: 140%;
  }

  > svg {
    right: 10px;
  }

  fieldset {
    border: 1px solid #e3e8ef !important;
    box-shadow: 0px 0px 9px rgba(41, 97, 99, 0) !important;
    transition: all 0.25s ease-in;
  }

  &.light {
    width: 420px;
    height: 50px;

    fieldset {
      border: 1px solid rgba(178, 184, 191, 1) !important;
    }
  }
`;

export const Placeholder = styled(Box)`
  color: #919ca7;
`;

export const Empty = styled(Box)`
  color: #919ca7;
  text-align: center;
  pointer-events: none;
`;

export const Option = styled(MenuItem)`
  padding: 6px 16px !important;
  width: 100%;
  border-radius: 40px !important;
  background: transparent;
  transition: all 0.25s ease-in-out;

  &:hover {
    background: #eceff2;
  }

  &.hidden {
    display: none;
  }
`;

export const Label = styled(InputLabel)`
  text-transform: uppercase;
  color: #919ca7;
  font-family: Open Sans Bold;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.6px;

  &.Mui-focused {
    color: #919ca7;
  }
`;
