import styled from 'styled-components';

export const personalInfoStyles = {
  Container: styled.div`
    padding: 30px;
  `,
  Contacts: styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 32px;
  `,
  Block: styled.div`
    margin-top: 40px;

    span {
      display: block;
      font-family: 'Open Sans';
      font-style: normal;
      font-size: 16px;
      line-height: 140%;
      color: #919ca7;
      margin-bottom: 16px;
    }
  `,
  Text: styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 16px;
    margin-top: 12px;
    line-height: 140%;
    color: #333333;
  `,

  Error: styled.div`
    font-size: 16px;
    color: #df5959;
    line-height: 140%;
    margin-bottom: 16px;
  `,
};
