import styled from 'styled-components';

export const agentCardStyles = {
  Agent: styled.div`
    padding: 40px 24px;
    border-left: 1px solid #eceff2;
    display: flex;
    align-items: center;
    width: 30%;
    //background: red;
    flex-direction: column;
    //justify-content: center;
  `,
  AgentAvatar: styled.div``,
  AgentTitle: styled.div`
    font-family: 'Open Sans Semi Bold';
    font-style: normal;
    font-size: 12px;
    margin: 20px 0 4px;
    line-height: 140%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #919ca7;
  `,
  AgentName: styled.div`
    font-family: 'Open Sans';
    font-style: normal;
    font-size: 14px;
    line-height: 140%;
    color: #333333;
  `,
  AgentNumber: styled.div`
    margin-top: 24px;
    font-family: 'Open Sans Semi Bold';
    font-style: normal;
    font-size: 14px;
    line-height: 140%;
    color: #49a3a6;
  `,
  AgentEmail: styled.div`
    margin-top: 8px;
    font-family: 'Open Sans Semi Bold';
    font-style: normal;
    font-size: 14px;
    line-height: 140%;
    color: #49a3a6;
  `,
};
