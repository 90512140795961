import { TableHeaderItemProps } from 'utils/ts/interfaces';
import { tableHeaderStyles } from './styles';

interface TableHeaderProps {
  list: TableHeaderItemProps[];
  gridTemplate: string;
  type: string;
}

const { Container, OneTab } = tableHeaderStyles;

export const TableHeader = ({ list, gridTemplate, type }: TableHeaderProps) => {
  return (
    <Container template={gridTemplate}>
      {list.map(({ id, title }) => (
        <OneTab key={id} type={type}>
          {title}
        </OneTab>
      ))}
    </Container>
  );
};
