import { useState, useEffect, FormEvent } from 'react';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import { OutlinedInput, Button, FormHelperText, FormControl, Box } from '@mui/material';
import { useUserCheck, useMainLoader } from 'hooks';
import { setCountNewApp } from 'store/slices/countNewApp';
import { setUserData } from 'store/slices/user';
import { setMainLoader } from 'store/slices/mainLoader';
import { sendRequest, baseApiUrl } from 'utils';
import { MessageNotice } from 'components/MessageNotice';
import { settingsStyles } from './styles';
import styles from './styles.module.scss';

const { Container, Title, Content, ButtonContainer, Label } = settingsStyles;

export const Settings = () => {
  const fields = { fullName: '', phoneNumber: '' };
  const [values, setValues] = useState(fields);
  const [isInvalid, setIsInvalid] = useState(false);
  const [helperTexts, setHelperTexts] = useState(fields);
  const [showNotice, setShowNotice] = useState(false);
  const noticeMessage = 'Данные успешно изменены!';
  const dispatch = useDispatch();
  const user = useUserCheck();
  const mainLoader = useMainLoader();
  const idForHelperText = 'outlined-helper-text-';
  const url = `${baseApiUrl}dashboard/manager/info/`;
  const { form, inputWrapper, input, button, helper, invalid, disabled } = styles;

  useEffect(() => {
    if (mainLoader) {
      dispatch(setMainLoader(!mainLoader));
    }
  }, [mainLoader]);

  useEffect(() => {
    if (user) setValues({ phoneNumber: user.phoneNumber, fullName: user.fullName });
  }, [user]);

  const changeValue = (event: any) => {
    const { name, value } = event.target;
    setIsInvalid(false);
    setValues({
      ...values,
      [name]: value,
    });
  };

  const giveAnError = (error: any) => {
    const { message, field } = error;
    setIsInvalid(true);
    setHelperTexts({ ...helperTexts, [field]: message });
  };

  const submitInfo = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const options = {
      method: 'PUT',
      data: {
        fullName: values.fullName,
        phoneNumber: values.phoneNumber,
      },
    };

    sendRequest(url, options).then(({ data }: any) => {
      const { error, countNewApp, ...user } = data;
      if (error !== undefined) {
        giveAnError(error.details);
      } else {
        setIsInvalid(false);
        dispatch(setUserData({ ...user }));
        if (countNewApp !== undefined) dispatch(setCountNewApp(countNewApp));
        setShowNotice(true);
      }
    });
  };

  return (
    <Container>
      <Title>Редактировать данные</Title>
      <Content>
        <MessageNotice
          message={noticeMessage}
          show={showNotice}
          status="success"
          onChange={setShowNotice}
        />
        <Box component="form" className={form} onSubmit={e => submitInfo(e)}>
          <FormControl
            variant="outlined"
            className={`${inputWrapper} ${isInvalid && invalid}`}
          >
            <Label htmlFor="outlined-input-phone">ФИО</Label>
            <OutlinedInput
              id="outlined-input-name"
              aria-describedby={`${idForHelperText}-name`}
              name="fullName"
              className={input}
              onChange={changeValue}
              value={values.fullName}
              autoFocus
              fullWidth
              required
            />
            <FormHelperText
              title={helperTexts.fullName}
              className={helper}
              id={`${idForHelperText}-name`}
            >
              {helperTexts.fullName}
            </FormHelperText>
          </FormControl>

          <FormControl
            variant="outlined"
            className={`${inputWrapper} ${isInvalid && invalid}`}
          >
            <Label htmlFor="outlined-input-phone">Телефон</Label>
            <InputMask
              mask="+7 999 999 99 99"
              value={values.phoneNumber}
              onChange={changeValue}
            >
              {/* @ts-ignore */}
              {(inputProps: any) => (
                <OutlinedInput
                  id="outlined-input-phone"
                  aria-describedby={`${idForHelperText}-phone`}
                  type="tel"
                  name="phoneNumber"
                  className={input}
                  {...inputProps}
                  autoFocus
                  fullWidth
                  required
                />
              )}
            </InputMask>
            <FormHelperText
              title={helperTexts.phoneNumber}
              className={helper}
              id={idForHelperText + 'phone'}
            >
              {helperTexts.phoneNumber}
            </FormHelperText>
          </FormControl>
          <ButtonContainer>
            <Button
              type="submit"
              className={`${button} ${
                values.fullName.length && values.phoneNumber.length ? '' : disabled
              }`}
            >
              Сохранить изменения
            </Button>
          </ButtonContainer>
        </Box>
      </Content>
    </Container>
  );
};
