import { TableListItemProps } from 'utils/ts/interfaces';
import { TableType } from 'utils/ts/customTypes';
import { getStatusTheme, numberWithSpaces } from 'utils';
import { Rating } from 'components/Rating';
import { DateFormat } from 'components/Date';
import { ApplicationStatus } from 'components/ApplicationStatus';
import { ArrowRight, EditIcon, RubleIcon } from 'assets/icons';
import { LinkContainer, Container, Content, Wrapper, Status } from './styles';

interface TableItemProps extends TableListItemProps {
  type: TableType;
  agentName?: string;
  agentEmail?: string;
  paymentDate?: string;
  amount?: number;
  gridTemplate: string;
  countColumn: number;
  applicationId?: number;
  isEdit?: boolean;
  onEdit?: (val: any) => void;
}

interface TableItemWrapperProps {
  id: number;
  type: TableType;
  children: JSX.Element | JSX.Element[];
}

const TableItemWrapper = ({ type, id, children }: TableItemWrapperProps) => (
  <>
    {type === 'application' ? (
      <LinkContainer to={`/application/${id}`}>{children}</LinkContainer>
    ) : (
      <Container>{children}</Container>
    )}
  </>
);

export const TableItem = ({
  id,
  applicationId,
  uin,
  createdAt,
  fullName,
  companyName,
  phoneNumber,
  email,
  manager,
  agent,
  status,
  rating,
  type,
  agentName,
  agentEmail,
  paymentDate,
  amount,
  gridTemplate,
  countColumn,
  isEdit,
  onEdit,
  name,
  group,
}: TableItemProps) => {
  const handlerEdit = () => {
    if (onEdit) {
      onEdit({
        id,
        applicationId,
        uin,
        createdAt,
        fullName,
        companyName,
        phoneNumber,
        email,
        manager,
        agent,
        status,
        rating,
        type,
        agentName,
        agentEmail,
        paymentDate,
        amount,
        name,
        group,
      });
    }
  };

  return (
    <TableItemWrapper type={type} id={id}>
      <Content template={gridTemplate}>
        {type === 'application' || type === 'payments' || type === 'dealerships' ? (
          <>
            <Wrapper type={type}>
              <span>{type === 'payments' ? applicationId : id}</span>
              {rating === undefined ? null : <Rating rating={rating} />}
            </Wrapper>
            {type === 'application' ? (
              <>
                <Wrapper type={type}>
                  <DateFormat date={createdAt} />
                </Wrapper>
                <Wrapper type={type}>
                  <span title={`${fullName || agentName}`}>{fullName || agentName}</span>
                  <div>
                    {companyName}
                    {`${uin ? ', ИНН ' + uin : ''}`}
                  </div>
                </Wrapper>
                <Wrapper type={type}>
                  <span>{phoneNumber}</span>
                  <div title={`${email}`}>{email}</div>
                </Wrapper>
              </>
            ) : null}
          </>
        ) : null}
        {type !== 'application' ? (
          <>
            <Wrapper type={type}>
              <span title={`${fullName || agentName || name}`}>
                {fullName || agentName || name}
              </span>
            </Wrapper>
            {type !== 'payments' && type !== 'dealerships' && (
              <Wrapper type={type}>
                <span>{phoneNumber}</span>
              </Wrapper>
            )}
            {type !== 'dealerships' && (
              <Wrapper type={type}>
                <span title={`${email || agentEmail}`}>{email || agentEmail}</span>
              </Wrapper>
            )}
            {type === 'clients' && countColumn > 3 ? (
              <>
                <Wrapper type={type}>
                  <span title={`${uin}`}>{uin}</span>
                </Wrapper>
                <Wrapper type={type}>
                  <span title={`${companyName}`}>{companyName}</span>
                </Wrapper>
              </>
            ) : null}

            {type === 'agents' && (
              <Wrapper type={type}>
                <span title={`${group?.name}`}>{group?.name ? group.name : 'Нет'}</span>
              </Wrapper>
            )}
            {type === 'payments' ? (
              <>
                {paymentDate !== undefined ? (
                  <Wrapper type={type}>
                    <DateFormat date={paymentDate} />
                  </Wrapper>
                ) : (
                  ''
                )}
                <Wrapper type={type}>
                  <span>
                    {amount ? numberWithSpaces(amount) : amount} <RubleIcon />
                  </span>
                </Wrapper>
              </>
            ) : null}
          </>
        ) : null}
        {type === 'application' ? (
          <>
            {agent !== undefined ? (
              <Wrapper type={type}>
                <span title={`${agent?.fullName}}`}>{agent?.fullName}</span>
                <div title={`${agent?.email}`}>{agent?.email}</div>
              </Wrapper>
            ) : null}
            <Wrapper type={type}>
              <span title={`${manager?.fullName}`}>{manager?.fullName}</span>
            </Wrapper>

            <Status>
              <ApplicationStatus status={status} theme={getStatusTheme(status)} />
              <ArrowRight />
            </Status>
          </>
        ) : null}
        {isEdit && (
          <Wrapper type={type} className="button" onClick={handlerEdit}>
            <EditIcon />
          </Wrapper>
        )}
      </Content>
    </TableItemWrapper>
  );
};
