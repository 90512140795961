import { useMainLoader } from 'hooks';
import { SuccessIcon } from 'assets/icons/statuses';
import { templateStyles } from './styles';

const { Container, Title, Content, Wrapper, Button } = templateStyles;

interface PageTemplateProps {
  children: JSX.Element | JSX.Element[];
  title: string;
  button?: {
    title: string;
    onClick: () => void;
  };
}

export const PageTemplate = ({ children, title, button }: PageTemplateProps) => {
  const load = useMainLoader();

  return (
    <Container>
      <Wrapper>
        <Title show={load}>{title}</Title>
        {button && (
          <Button className="wrapper" onClick={button.onClick}>
            <SuccessIcon />
            <div className="title">{button.title}</div>
          </Button>
        )}
      </Wrapper>
      <Content>{children}</Content>
    </Container>
  );
};
