import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { OutlinedInput } from '@mui/material';
import { InputWrapper } from './styles';
import { SearchIcon } from 'assets/icons';

interface SearchProps {
  defaultValue: string;
}

export const Search = ({ defaultValue }: SearchProps) => {
  const [value, setValue] = useState(defaultValue);
  const [searchParams, setSearchParams] = useSearchParams();

  const changeInputValue = (event: any) => {
    setValue(event.target.value);
    setSearchParams({ search: `${event.target.value}`, page: '1' });
  };

  const commonInputProps = {
    inputProps: {
      'aria-label': 'weight',
    },
    type: 'search',
    className: 'input',
    fullWidth: true,
    required: true,
    placeholder: 'Поиск по личным данным клиента (ФИО, ИНН и т.д)',
  };

  return (
    <InputWrapper variant="outlined">
      <OutlinedInput value={value} onChange={changeInputValue} {...commonInputProps} />
      <SearchIcon className="icon" />
    </InputWrapper>
  );
};
