import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../generateState';

interface CountNewAppProps {
  countNewApp: number | null;
}
const initialState: CountNewAppProps = { countNewApp: null };

const slice = createSlice({
  name: 'countNewApp',
  initialState,
  reducers: {
    setCountNewApp: (state, { payload }: PayloadAction<number | null>) => {
      state.countNewApp = payload;
    },
  },
});

export const { setCountNewApp } = slice.actions;

export const selectCountNewApp = (state: RootState) => state.countNewApp;

export default slice.reducer;
