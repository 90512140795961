import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../generateState';

interface MainLoaderProps {
  mainLoader: boolean;
}
const initialState: MainLoaderProps = { mainLoader: true };

const slice = createSlice({
  name: 'mainLoader',
  initialState,
  reducers: {
    setMainLoader: (state, { payload }: PayloadAction<boolean>) => {
      state.mainLoader = payload;
    },
  },
});

export const { setMainLoader } = slice.actions;

export const selectMainLoader = (state: RootState) => state.mainLoader;

export default slice.reducer;
