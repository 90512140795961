import styled from 'styled-components';

export const customTheme = {
  RelativeBlock: styled.div`
    position: relative;
    overflow: hidden;
  `,
  MainContainer: styled.div`
    background: #eceff2;
    min-height: calc(100vh - 70px);
    padding-left: 135px;
    padding-right: 135px;
    padding-bottom: 75px;
    padding-top: 40px;
    position: relative;
  `,
};
