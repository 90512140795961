import { FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';

export const InputWrapper = styled(FormControl)`
  width: 100%;

  .input {
    color: rgba(145, 156, 167, 1);
    border-radius: 10px;
    background: #fff;
  }

  .input {
    input {
      height: 45px;
    }
  }
  input {
    box-sizing: border-box;
    outline: none;
    padding: 12px 13px;
    width: 100%;
    border-radius: 10px;
    box-shadow: #333333 0 0 0 0;
    transition: all.2s ease-in-out;

    &:focus {
      color: #333333;
      box-shadow: #333333 0 0 0 1px;
    }
  }

  fieldset {
    border: none;
    outline: none;
  }

  .icon {
    position: absolute;
    right: 16px;
    top: 14px;
  }
`;
