import styled from 'styled-components';

export const notFoundStyles = {
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
  `,

  Decoration: styled.div`
    color: #eceff2;
    font-size: 10rem;
  `,
};
