import { DocIcon, CloseIcon } from 'assets/icons';
import { Title, ImageWrapper, Link, RemoveWrapper, LinkWrapper } from './styles';
import { CircularProgress } from '@mui/material';

interface FileItemProps {
  id: number;
  name: string;
  url: string;
  onRemove?: (id: number) => void;
  loader?: boolean;
}

export const FileItem = ({ id, name, url, onRemove, loader }: FileItemProps) => {
  const removeElement = (event: any) => {
    if (onRemove) onRemove(id);
  };

  return (
    <LinkWrapper>
      <Link href={url} download>
        {loader ? (
          <div>
            <CircularProgress />
          </div>
        ) : (
          <ImageWrapper>
            <DocIcon />
          </ImageWrapper>
        )}

        <Title>{name}</Title>
      </Link>
      {!loader && (
        <RemoveWrapper onClick={removeElement}>
          <CloseIcon />
        </RemoveWrapper>
      )}
    </LinkWrapper>
  );
};

export default FileItem;
