import styled from 'styled-components';

export const singleApplicationStyles = {
  Container: styled.div`
    background: white;
    border-radius: 12px;
  `,
  Content: styled.div`
    display: flex;
  `,
  Left: styled.div`
    width: 70%;
    border-bottom: 20px;
  `,
};
