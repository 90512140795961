import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCountNewApp } from 'store/slices/countNewApp';
import { setMainLoader } from 'store/slices/mainLoader';
import { determinePageNumber } from 'utils';
import { getContent } from 'utils/requests';
import { changeHeaderPropsInTable } from 'utils/table';
import { UrlProps, PageProps } from 'utils/ts/interfaces';
import { table, tabs } from 'assets/data/clients';
import { Table } from 'components/Table';
import { Tabs } from 'components/Tabs';
import { Pagination } from 'components/Pagination';
import { Loader } from 'components/Loader';
import { PageTemplate } from 'components/PageTemplate';
import { Content } from './styles';

export const Clients = ({ param }: PageProps) => {
  const [content, setContent] = useState<any>([]);
  const [countPages, setCountPages] = useState(0);
  const locationQuery = useLocation().search;
  const [activeTab, setActiveTab] = useState(param);
  const [loading, setLoading] = useState(false);
  const [tableProps, setTableProps] = useState(
    changeHeaderPropsInTable(table, activeTab),
  );
  const dispatch = useDispatch();
  const pageName = 'clients';
  const pageNumber = determinePageNumber(locationQuery) || 1;
  let params: UrlProps = {
    content: pageName,
    activeTab,
    query: locationQuery,
  };

  const sendRequest = (config: UrlProps) => {
    getContent(config).then(({ data }: any) => {
      const { result, pages, countNewApp } = data;
      setCountPages(pages);
      setTimeout(() => {
        setContent(result);
        setLoading(false);
      }, 250);
      dispatch(setMainLoader(false));
      if (countNewApp !== undefined) dispatch(setCountNewApp(countNewApp));
    });
  };

  useEffect(() => {
    if (locationQuery) {
      setLoading(true);
      params.query = locationQuery;
      sendRequest(params);
    }
  }, [pageNumber]);

  useEffect(() => {
    if (!locationQuery) {
      setLoading(true);
      params.activeTab = activeTab;
      // params.query = 1;
      params.query = locationQuery;
      sendRequest(params);
    }
    setTableProps(changeHeaderPropsInTable(table, activeTab));
  }, [activeTab]);

  return (
    <PageTemplate title="Клиенты">
      <Content>
        <Tabs tabs={tabs} changeParamUrl={setActiveTab} commonUrl="/clients">
          <Loader active={loading}>
            <Table
              content={content}
              {...tableProps}
              type={pageName}
              activeTab={activeTab}
            />
          </Loader>
          <Pagination count={countPages} />
        </Tabs>
      </Content>
    </PageTemplate>
  );
};
