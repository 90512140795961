import { useEffect, useState } from 'react';
import { useLocation, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useUserCheck, useActiveStatusCheck } from 'hooks';
import { defaultValueForDetails, clearLocalStorage, addTitlePage } from 'utils';
import { setUserData } from 'store/slices/user';
import { setDetails } from 'store/slices/applicationDetails';
import { Notice } from 'components/Notice';
import { LogoutIcon } from 'assets/icons';
import { Setting } from 'assets/icons';
import { headerStyles } from './styles';
import { getNavigation } from 'utils/getNavigation';
import { Roles } from 'shared/roles';

const { Container, Links, Link, Email, Logout, Right, Line, Settings } = headerStyles;

export const Header = () => {
  const [isActive, setIsActive] = useState<number | null>(1);
  const dispatch = useDispatch();
  const user = useUserCheck();
  const activeStatus = useActiveStatusCheck();
  const settingsPath = '/settings';
  const { pathname, state } = useLocation();
  const splittedPath = pathname.split('/');
  const [headerRoutes, setHeaderRoutes] = useState(getNavigation(user?.role || 3));

  useEffect(() => {
    changeActive(true);
  }, [activeStatus]);

  useEffect(() => {
    changeActive();
    addTitlePage(pathname);
  }, [pathname]);

  useEffect(() => {
    setHeaderRoutes(getNavigation(user?.role || 3));
  }, [user?.role]);

  const changeActive = (withSide?: boolean) => {
    if (withSide) {
      if (activeStatus) {
        const route = headerRoutes.filter(item => item.route.includes(activeStatus));

        return route.length && setIsActive(route[0].id);
      }
    } else {
      if (state?.prev) setIsActive(null);
      const currentRoute = headerRoutes.filter(item =>
        item.route.includes(splittedPath[1]),
      );

      if (splittedPath[1] === 'application') {
        const route = currentRoute.filter(item => item.route.includes(splittedPath[2]));

        return route.length && setIsActive(route[0].id);
      } else {
        return currentRoute.length && setIsActive(currentRoute[0].id);
      }
    }
  };

  const logoutUser = () => {
    clearLocalStorage();
    dispatch(setUserData(null));
    dispatch(setDetails(defaultValueForDetails));
  };

  return (
    <Container>
      <Links>
        {headerRoutes.map(({ route, title, id }) => (
          <Link key={id} active={id === isActive}>
            <NavLink to={route} state={{ prev: pathname }}>
              {title}
            </NavLink>
            {id === 1 ? <Notice /> : null}
          </Link>
        ))}
      </Links>
      <Right>
        {user && <Email>{user.email}</Email>}
        {user?.role === Roles.MANAGER_ID && (
          <Settings active={settingsPath.includes(splittedPath[1])}>
            <NavLink to={settingsPath}>
              <Setting />
            </NavLink>
          </Settings>
        )}
        <Line></Line>
        <Logout onClick={() => logoutUser()}>
          <LogoutIcon />
        </Logout>
      </Right>
    </Container>
  );
};
