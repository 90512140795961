export const tabs = [
  {
    id: 1,
    title: 'Зарегистрированные',
    param: 'with-reg',
  },
  {
    id: 2,
    title: 'Без регистрации',
    param: 'without-reg',
  },
];

export const table = [
  {
    type: tabs[0].param,
    titles: [
      { title: '№ / оценка', id: 1, name: 'id' },
      { title: 'Дата', id: 2, name: 'date' },
      { title: 'Клиент', id: 3 },
      { title: 'Контакты', id: 4 },
      { title: 'Агент', id: 5 },
      { title: 'Менеджер', id: 6 },
      { title: 'Статус', id: 7 },
    ],
  },
  {
    type: tabs[1].param,
    titles: [
      { title: '№', id: 1, name: 'id' },
      { title: 'Дата', id: 2, name: 'data' },
      { title: 'Клиент', id: 3 },
      { title: 'Контакты', id: 4 },
      { title: 'Менеджер', id: 6 },
      { title: 'Статус', id: 7 },
    ],
  },
];
