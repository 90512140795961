import { Box, OutlinedInput } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Input = styled(OutlinedInput)`
  color: rgba(145, 156, 167, 1);
  border: 1px solid rgba(178, 184, 191, 1);
  border-radius: 10px;

  &.Mui-disabled {
    background: #e3e8ef !important;
  }

  input {
    height: 45px;
    box-sizing: border-box;
    outline: none;
    padding: 12px 13px;
    width: 100%;
    border-radius: 10px;

    &::placeholder {
      color: #919ca7;
      font-size: 14px;
      line-height: 140%;
      opacity: 1;
    }

    &:focus {
      color: #333333;
      box-shadow: #333333 0 0 0 1px;
    }
  }

  fieldset {
    border: none;
    outline: none;
  }
`;

export const Error = styled(Box)`
  color: rgba(223, 89, 89, 1);
  font-family: 'Open Sans Semi Bold';
  font-style: normal;
  font-size: 14px;
  line-height: 116%;
`;
