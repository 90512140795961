import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { defaultValueForDetails } from 'utils';
import { DetailInfoProps } from 'utils/ts/interfaces';
import type { RootState } from '../generateState';

interface AppDetailInfoProps {
  applicationDetails: DetailInfoProps;
}
const initialState: AppDetailInfoProps = { applicationDetails: defaultValueForDetails };

export const slice = createSlice({
  name: 'applicationDetails',
  initialState,
  reducers: {
    setDetails: (state, { payload }: PayloadAction<DetailInfoProps>) => {
      state.applicationDetails = payload;
    },
  },
});

export const { setDetails } = slice.actions;

export const selectCurrentApplication = (state: RootState) => {
  return state.applicationDetails;
};

export default slice.reducer;
