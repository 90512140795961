import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppDetailsCheck } from 'hooks';
import { Navigation } from 'routes';
import { addTitlePage } from 'utils';
import { mainLayoutStyles } from './styles';

const Container = mainLayoutStyles.Container;

export const MainLayout = () => {
  const { id } = useAppDetailsCheck();
  const { pathname } = useLocation();

  useEffect(() => {
    addTitlePage(pathname, id);
  }, [pathname, id]);

  return (
    <Container>
      <Navigation />
    </Container>
  );
};
