import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setDetails } from 'store/slices/applicationDetails';
import { useUserCheck, useAppDetailsCheck } from 'hooks';
import { setCountNewApp } from 'store/slices/countNewApp';
import { sendRequest, findApplicationPayment, baseApiUrl } from 'utils';
import { Loader } from 'components/Loader';
import { Message404 } from 'components/Message404';
import { ApplicationControlPanel } from 'components/ApplicationControlPanel';
import { PersonalInfo } from 'components/PersonalInfo';
import { ApplicationInfo } from 'components/ApplicationInfo';
import { AgentCard } from 'components/AgentCard';
import { singleApplicationStyles } from './styles';
import { Roles } from 'shared/roles';

export const SingleApplication = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error404, setError404] = useState<any>(null);
  const user = useUserCheck();
  const [isEdit, setIsEdit] = useState(user?.role === Roles.MANAGER_ID);
  const sectionsInfo = useAppDetailsCheck();
  const isHavingsUser = sectionsInfo.manager === null || user?.id === sectionsInfo.userId;

  const { Container, Left, Content } = singleApplicationStyles;

  useEffect(() => {
    getDataFunc();
  }, []);

  useEffect(() => {
    setIsEdit(user?.role === Roles.MANAGER_ID && user?.id === sectionsInfo.userId);
  }, [user?.role, user?.id, sectionsInfo.userId]);

  const getDataFunc = () => {
    const url = `${baseApiUrl}dashboard/application/${id}/`;

    sendRequest(url).then((res: any) => {
      const { data } = res;
      const { payment, countNewApp, error } = data;

      if (res.status === 200 && !error) {
        let newInfo = setDetails({ ...sectionsInfo, ...data });

        if (payment && payment.length > 0 && id)
          newInfo = setDetails({
            ...sectionsInfo,
            ...data,
            payment: findApplicationPayment(id, payment),
          });

        dispatch(newInfo);
        if (countNewApp !== undefined) dispatch(setCountNewApp(countNewApp));
        setTimeout(() => setIsLoading(false), 250);
      } else {
        setError404(error ? { ...error, status: res.status } : res.status);
        setTimeout(() => setIsLoading(false), 250);
      }
    });
  };

  return (
    <Container>
      <Loader active={isLoading}>
        {error404 ? (
          <Message404 error={error404} />
        ) : (
          <>
            <ApplicationControlPanel
              {...sectionsInfo}
              isHavingsUser={isHavingsUser}
              isEdit={isEdit}
            />
            <Content>
              <div>{error404}</div>
              <Left>
                <ApplicationInfo
                  {...sectionsInfo}
                  isHavingsUser={isHavingsUser}
                  isManager={isEdit}
                />
                <PersonalInfo {...sectionsInfo} isManager={isEdit} />
              </Left>
              {sectionsInfo.agent && (
                <AgentCard
                  {...sectionsInfo}
                  isHavingsUser={isHavingsUser}
                  isManager={isEdit}
                />
              )}
            </Content>
          </>
        )}
      </Loader>
    </Container>
  );
};
