import { titles } from 'assets/data/titles';

export function addTitlePage(path: string, id?: string | number) {
  const routes = titles.filter(item => path.includes(item.route));

  if (routes.length) {
    if (routes.length > 1) {
      const splittedPath = path.split('/');

      if (splittedPath.length > 2) {
        const route = routes.filter(item => {
          return item.route.includes(splittedPath[2]);
        });

        document.title = route[0].title;
      }
    } else {
      if (routes[0].route === '/application') {
        document.title = `${routes[0].title}${id ? ' №' + id : ''}`;
      } else {
        document.title = routes[0].title;
      }
    }
  }
}
