import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCountNewApp } from 'store/slices/countNewApp';
import { setMainLoader } from 'store/slices/mainLoader';
import { determinePageNumber } from 'utils';
import { getContent } from 'utils/requests';
import { UrlProps } from 'utils/ts/interfaces';
import { table } from 'assets/data/managers';
import { PageTemplate } from 'components/PageTemplate';
import { Table } from 'components/Table';
import { Pagination } from 'components/Pagination';
import { Loader } from 'components/Loader';
import { RecordModal } from 'components/RecordModal/RecordModal';
import { profileManager } from 'assets/data/forms';
import { useUserCheck } from 'hooks';
import { Roles } from 'shared/roles';
import { Message404 } from 'components/Message404';
import { Content } from './styles';

export const Managers = () => {
  const [content, setContent] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [countPages, setCountPages] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [inputs, setInputs] = useState(profileManager);
  const [recordId, setRecordId] = useState<null | number>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [isError, setIsError] = useState<any>(null);
  const dispatch = useDispatch();
  const user = useUserCheck();
  const locationQuery = useLocation().search;
  const pageNumber = determinePageNumber(locationQuery) || 1;
  const pageName = 'managers';
  let params: UrlProps = {
    // @ts-ignore
    content: 'manager/info/admin',
    query: locationQuery,
  };

  const sendRequest = (config: UrlProps) => {
    setLoading(true);
    getContent(config).then((res: any) => {
      const { data } = res;
      const { result, pages, countNewApp, error } = data;
      if (res.status === 200 && !error) {
        setCountPages(pages);
        setTimeout(() => {
          setContent(result);
          setLoading(false);
        }, 250);
        dispatch(setMainLoader(false));
        if (countNewApp !== undefined) dispatch(setCountNewApp(countNewApp));
      } else {
        setIsError(error ? { ...error, status: res.status } : res.status);
        setTimeout(() => setLoading(false), 250);
      }
    });
  };

  const handlerModal = () => {
    setInputs(profileManager);
    setShowModal(true);
    setIsEdit(false);
  };

  const editItem = (data: any) => {
    const newInputs = profileManager.map(item => ({
      ...item,
      value: data[item.field],
      required: item.field === 'password' ? false : item.required,
    }));
    setRecordId(data.id);
    setInputs(newInputs);
    setShowModal(true);
    setIsEdit(true);
  };

  const changeContent = (data: any) => {
    let newContent = [...content];

    if (isEdit) {
      const index = content.findIndex(item => item.id === data.id);
      newContent[index] = data;
    } else {
      newContent = [...content, data];
    }

    setContent(newContent);
  };

  useEffect(() => {
    setLoading(true);

    params.query = locationQuery;
    sendRequest(params);
  }, [pageNumber]);

  return (
    <PageTemplate
      title="Менеджеры"
      button={{ title: 'Добавить позицию', onClick: handlerModal }}
    >
      <Content>
        <Loader active={loading}>
          {isError ? (
            <Message404 error={isError} />
          ) : (
            <Table
              content={content}
              titles={table}
              type={pageName}
              isEdit={user?.role === Roles.ADMIN_ID}
              onEdit={editItem}
            />
          )}
        </Loader>
        <Pagination count={countPages} />
      </Content>
      <RecordModal
        title={`${isEdit ? 'Редактирование' : 'Добавление'} записи`}
        show={showModal}
        inputs={inputs}
        toggleShow={setShowModal}
        endpoint={`/manager/info/admin/${isEdit ? `${recordId}/` : ''}`}
        method={`${isEdit ? 'PUT' : 'POST'}`}
        onSave={changeContent}
      />
    </PageTemplate>
  );
};
