import styled from 'styled-components';

export const tableStyles = {
  Container: styled.div`
    display: grid;
    grid-template-areas: 'header' 'body';
    position: relative;
    border-radius: 12px;
    background: #ffffff;
  `,
  TableBody: styled.div`
    display: grid;
    grid-area: body;
    grid-template-columns: repeat(1, 1fr);
  `,
  Empty: styled.div`
    font-family: 'Open Sans Semi Bold';
    font-style: normal;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    padding: 20px 0;
  `,
};
